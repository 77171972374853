import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Input from '../Common/Form/Input';
import {
	EditProduct,
	IMeasure,
	IProduct,
	IProductType,
	initialValues,
	validationSchema,
} from '../../containers/Product/ProductAssets';
import Switch from '../Common/Form/Switch';
import { IFilterField } from '../../interfaces/BudgetOrderFourFields';
import Autocomplete from '../Common/Form/Autocomplete';
import { ConversionType } from '../../enums/ConversionType';
import FileUpload from '../Common/Form/DropzoneUpload';
import { IBranch } from '../../containers/Branch/BranchAssets';
import { ICompanyWithoutDetails } from '../../containers/Company/CompanyAssets';

interface ProductFormProps {
	product?: IProduct;
	accountingAccounts: IFilterField[];
	measures: IMeasure[];
	productTypes: IProductType[];
	branches: IBranch[];
	companies: ICompanyWithoutDetails [];
	onSubmit: (values: EditProduct) => void;
	loading: boolean;
}

const ProductForm = ({
	product,
	accountingAccounts,
	measures,
	productTypes,
	branches,
	companies,
	onSubmit,
	loading,
}: ProductFormProps): JSX.Element => {
	const initialValuesLoad = useMemo(() => (product ? {
		companyId: product.companyId,
		branchId: product.branchId,
		code: product.code,
		description: product.description,
		erpCode: product.erpCode,
		erpDescription: product.erpDescription,
		barCode: product.barCode,
		primaryMeasureId: product.primaryMeasureId,
		secondaryMeasureId: product.secondaryMeasureId,
		typeId: product.typeId,
		accountingAccountId: product.accountingAccountId,
		apportionmentControl: product.apportionmentControl,
		conversionIndex: product.conversionIndex,
		conversionType: product.conversionType,
		qualityControl: product.qualityControl,
		locateControl: product.locateControl,
		image: null,
	} : initialValues), [product]);

	return (
		<Formik
			initialValues={initialValuesLoad}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			validateOnChange={false}
			validateOnBlur={false}
		>
			<Form noValidate>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Autocomplete
							name="companyId"
							label="Empresa"
							options={companies}
							labelKey="name"
							valueKey="id"
							valueLabel="code"
							required
						/>
					</Grid>
					<Grid item xs={6}>
						<Autocomplete
							name="branchId"
							label="Filial"
							options={branches}
							labelKey="name"
							valueKey="id"
							valueLabel="code"
							required
						/>
					</Grid>
					<Grid item xs={6}>
						<Input.InputField
							id="code"
							name="code"
							label="Código"
							required
							fullWidth
						/>
					</Grid>
					<Grid item xs={6}>
						<Input.InputField
							id="description"
							name="description"
							label="Descrição"
							required
							fullWidth
						/>
					</Grid>
					<Grid item xs={6}>
						<Input.InputField
							id="erpCode"
							name="erpCode"
							label="Código ERP"
							fullWidth
						/>
					</Grid>
					<Grid item xs={6}>
						<Input.InputField
							id="erpDescription"
							name="erpDescription"
							label="Descrição ERP"
							fullWidth
						/>
					</Grid>
					<Grid item xs={6}>
						<Input.InputField
							id="barCode"
							name="barCode"
							label="Código de Barras"
							required
							fullWidth
						/>
					</Grid>
					<Grid item xs={3}>
						<Autocomplete
							name="primaryMeasureId"
							label="1ª Unidade de Medida"
							options={measures}
							labelKey="description"
							valueKey="id"
							required
						/>
					</Grid>
					<Grid item xs={3}>
						<Autocomplete
							name="secondaryMeasureId"
							label="2ª Unidade de Medida"
							options={measures}
							labelKey="description"
							valueKey="id"
						/>
					</Grid>
					<Grid item xs={6}>
						<Autocomplete
							name="typeId"
							label="Tipo"
							options={productTypes}
							labelKey="description"
							valueKey="id"
							required
						/>
					</Grid>
					<Grid item xs={6}>
						<Autocomplete
							name="accountingAccountId"
							label="Conta Contábel"
							options={accountingAccounts}
							labelKey="name"
							valueKey="code"
						/>
					</Grid>
					<Grid item xs={6}>
						<Autocomplete
							name="conversionType"
							label="Tipo de Conversão"
							options={[
								{ label: 'Divisor', value: ConversionType.DIVIDER },
								{ label: 'Multiplicador', value: ConversionType.MULTIPLIER },
							]}
							labelKey="label"
							valueKey="value"
						/>
					</Grid>
					<Grid item xs={6}>
						<Input.InputField
							id="conversionIndex"
							name="conversionIndex"
							label="Índice de Conversão"
							type="number"
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}>
						<Switch
							name="qualityControl"
							label="Controle de Qualidade"
						/>
					</Grid>
					<Grid item xs={4}>
						<Switch
							name="locateControl"
							label="Controle de Localização"
						/>
					</Grid>
					<Grid item xs={4}>
						<Switch
							name="apportionmentControl"
							label="Controle de Rateio"
						/>
					</Grid>
					<Grid item xs={12}>
						<FileUpload
							name="image"
							currentFile={product?.imageB64}
						/>
					</Grid>
				</Grid>
				<Box marginTop={2}>
					<LoadingButton
						loading={loading}
						type="submit"
						variant="contained"
						color="primary"
					>
						Salvar
					</LoadingButton>
				</Box>
			</Form>
		</Formik>
	);
};

ProductForm.defaultProps = {
	product: undefined,
};

export default ProductForm;
