import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SelectChangeEvent } from '@mui/material';
import SalePriceFormationForm from './SalePriceFormationForm';
import {
	IExtendedSalePriceFormation,
	ISalePriceFormation,
} from '../../containers/SalePriceFormation/SalePriceFormationAssets';
import { ISalePriceFormationEditActions, IState } from '../../containers/SalePriceFormation/SalePriceFormationEdit';
import { SalePriceFormationStatus } from '../../enums/SalePriceFormationStatus';
import { Loading } from '../Common/Loading';

interface SalePriceFormationEditProps extends IState, ISalePriceFormationEditActions {
	loading: boolean;
	salePriceFormation?: ISalePriceFormation;
}

const SalePriceFormationEdit = ({
	loading,
	salePriceFormation,
	costBaseMonths,
	indirectCosts,
	averagePaymentTerms,
	rangeCommissions,
	items,
	averagePaymentTermSalePriceFormations,
	rangeCommissionSalePriceFormations,
	getSalePriceFormationById,
	getCostBaseMonths,
	handleEditSalePriceFormation,
	handleUpdateCostBaseMonth,
	getIndirectCosts,
	createIndirectCost,
	getAveragePaymentTerms,
	createAveragePaymentTerm,
	getRangeCommissions,
	createRangeCommission,
	upsertAveragePaymentTermToFormation,
	removeAveragePaymentTermFromFormation,
	upsertRangeCommissionToFormation,
	removeRangeCommissionFromFormation,
}: SalePriceFormationEditProps): JSX.Element => {
	const { id } = useParams();

	useEffect(() => {
		if (id) {
			getSalePriceFormationById(id);
			getCostBaseMonths();
			getIndirectCosts();
			getAveragePaymentTerms();
			getRangeCommissions();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const handleSubmit = useCallback(
		(values: IExtendedSalePriceFormation, status: SalePriceFormationStatus) => {
			const {
				productCode,
				productDescription,
				currentCostId,
				currentCostRate,
				currentCostCode,
				currentCostDescription,
				newCostDescription,
				newCostRate,
				currentAveragePaymentTermId,
				currentAveragePaymentTermRate,
				currentAveragePaymentTermCode,
				currentAveragePaymentTermDescription,
				newAveragePaymentTermDescription,
				newAveragePaymentTermRate,
				newAveragePaymentTerm,
				currentRangeCommissionId,
				currentRangeCommissionRate,
				currentRange,
				newRangeCommissionRate,
				indexValue,
				indexValues,
				averageTerm,
				newRange,
				discountIndex,
				...filteredValues
			} = values;

			const updatedValues = {
				...filteredValues,
				costBaseMonth: values.costBaseMonth ? new Date(values.costBaseMonth).toISOString() : '',
				totalDirectCost: Number(values.totalDirectCost),
				status,
			};

			if (values.id) {
				handleEditSalePriceFormation(updatedValues, values.id);
			} else {
				handleEditSalePriceFormation(updatedValues);
			}
		},
		[handleEditSalePriceFormation],
	);

	const handleCostBaseMonthChange = useCallback(
		(event: SelectChangeEvent) => {
			if (id && salePriceFormation) {
				const selectedDate = new Date(event.target.value as string);
				handleUpdateCostBaseMonth(id, selectedDate);
			}
		},
		[id, salePriceFormation, handleUpdateCostBaseMonth],
	);

	const isReadOnly = useMemo(() => {
		if (!salePriceFormation) return false;
		const { status } = salePriceFormation;
		return [
			SalePriceFormationStatus.ACTIVE,
			SalePriceFormationStatus.HISTORIC,
			SalePriceFormationStatus.REJECTED,
		].includes(status);
	}, [salePriceFormation]);

	if (id
		&& !salePriceFormation
	) {
		return <Loading />;
	}

	return (
		<Paper sx={{ mt: 3, p: 3 }} elevation={3}>
			<Stack spacing="1rem">
				<Typography variant="h4" display="block" color="primary" gutterBottom>
					Editar Formação de Venda
				</Typography>
				<SalePriceFormationForm
					loading={loading}
					onSubmit={handleSubmit}
					salePriceFormation={salePriceFormation}
					costBaseMonths={costBaseMonths}
					items={items}
					averagePaymentTermSalePriceFormations={averagePaymentTermSalePriceFormations}
					rangeCommissionSalePriceFormations={rangeCommissionSalePriceFormations}
					handleCostBaseMonthChange={handleCostBaseMonthChange}
					indirectCosts={indirectCosts}
					createIndirectCost={createIndirectCost}
					averagePaymentTerms={averagePaymentTerms}
					createAveragePaymentTerm={createAveragePaymentTerm}
					rangeCommissions={rangeCommissions}
					createRangeCommission={createRangeCommission}
					upsertAveragePaymentTermToFormation={upsertAveragePaymentTermToFormation}
					removeAveragePaymentTermFromFormation={removeAveragePaymentTermFromFormation}
					upsertRangeCommissionToFormation={upsertRangeCommissionToFormation}
					removeRangeCommissionFromFormation={removeRangeCommissionFromFormation}
					isReadOnly={isReadOnly}
				/>
			</Stack>
		</Paper>
	);
};

SalePriceFormationEdit.defaultProps = {
	salePriceFormation: undefined,
};

export default SalePriceFormationEdit;
