import React, { useCallback, useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import {
	Form, FormikContext, useFormik,
} from 'formik';
import Input from '../Common/Form/Input';
import Autocomplete from '../Common/Form/Autocomplete';
import { ICompanyWithoutDetails } from '../../containers/Company/CompanyAssets';
import { BranchParams, InventoryQueryParams } from '../../containers/Inventory/InventoryAssets';
import { DatePicker } from '../Common/Form/DatePicker';
import { IBranch } from '../../containers/Branch/BranchAssets';
import { filterObject } from '../../helpers/Utils';

const statusList = [
	{ label: 'Pendente', id: 0 },
	{ label: 'Em andamento', id: 1 },
	{ label: 'Concluído', id: 2 },
];

interface InventoryFilterProps {
	companies: ICompanyWithoutDetails[];
	handlerValueFilter: (values: Partial<InventoryQueryParams>) => void;
	getBranches: (params?: BranchParams) => void;
	branches: IBranch[];
	initialValues: InventoryQueryParams
}

const InventoryFilter = (
	{
		companies,
		handlerValueFilter,
		getBranches,
		initialValues,
		branches,
	}: InventoryFilterProps,
): JSX.Element => {
	const onReset = useCallback((resetForm) => {
		handlerValueFilter({});
		resetForm();
	}, [handlerValueFilter]);

	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			handlerValueFilter(filterObject(values));
		},
	});

	useEffect(() => {
		if (formik.values.companyId) {
			getBranches({
				companyId: formik.values.companyId,
			});
		}
	}, [formik.values.companyId, getBranches]);

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>
					<Grid item xs={12}>
						<Autocomplete
							label="Status"
							name="status"
							valueKey="id"
							valueLabel="id"
							labelKey="label"
							options={statusList || []}
							multiple
							fullWidth
						/>
					</Grid>

					<Grid item xs={12}>
						<Input.InputField
							id="document"
							name="document"
							label="Documento"
							autoComplete="off"
							fullWidth
						/>
					</Grid>

					<Grid item xs={12}>
						<Autocomplete
							label="Empresa"
							name="companyId"
							valueKey="id"
							valueLabel="code"
							labelKey="name"
							options={companies || []}
							closeOnSelect
							fullWidth
						/>
					</Grid>

					<Grid item xs={12}>
						<Autocomplete
							label="Filial"
							name="branchIds"
							valueKey="id"
							valueLabel="code"
							labelKey="name"
							options={branches || []}
							multiple
							fullWidth
							disabled={!formik.values.companyId}
						/>
					</Grid>

					<Grid container spacing={2} item xs={12}>
						<Grid item xs={6}>
							<DatePicker
								name="startDate"
								label="Data início"
							/>
						</Grid>

						<Grid item xs={6}>
							<DatePicker
								name="endDate"
								label="Data Final"
							/>
						</Grid>
					</Grid>

					<Grid
						item
						xs={12}
						sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}
					>
						<Button variant="outlined" onClick={() => onReset(formik.handleReset)}>
							Restaurar
						</Button>
						<Button variant="contained" type="submit">
							Filtrar
						</Button>
					</Grid>
				</Grid>
			</Form>
		</FormikContext.Provider>
	);
};

export default InventoryFilter;
