import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import ProfitabilityAnalysisEditPresentational from '../../components/ProfitabilityAnalysis/ProfitabilityAnalysisEdit';
import { ISaleOrderSimulation, ISaleOrderSimulationItem } from './ProfitabilityAnalysisAssets';
import {
	getSaleOrderSimulationById,
	updateSaleOrderSimulation,
	updateSaleOrderSimulationItem,
	updateSelectedRangeSimulation,
	updateSelectedAveragePaymentSimulation,
} from '../../services/profitabilityAnalysis';
import { IIndirectCostSalePriceFormation } from '../SalePriceFormation/SalePriceFormationAssets';
import { upsertIndirectCostToFormation } from '../../services/salePriceFormation';

enum ActionType {
	LOADING,
	SALE_ORDER_SIMULATION,
}

interface IState {
	loading: boolean;
	saleOrderSimulation?: ISaleOrderSimulation;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| {
        type: ActionType.SALE_ORDER_SIMULATION;
        payload: { saleOrderSimulation: ISaleOrderSimulation }
    };

interface IProfitabilityAnalysisEditActions {
	setLoading(loading: boolean): void;
	getSaleOrderSimulationById(id: string): void;
    updateSaleOrderSimulation(id: string, data: ISaleOrderSimulation): void;
	updateSaleOrderSimulationItem(
		simulationId: string,
		itemId: string,
		data: ISaleOrderSimulationItem,
	): void;
	upsertIndirectCostToFormation(data: IIndirectCostSalePriceFormation): void;
	updateSelectedRangeSimulation(
		rangeCommissionId: string,
		salePriceFormationId: string,
		selectedRangeSimulation: boolean,
	): void;
	updateSelectedAveragePaymentSimulation(
		averagePaymentTermId: string,
		salePriceFormationId: string,
		selectedAveragePaymentSimulation: boolean,
	): void;
}

const initialState: IState = {
	loading: false,
	saleOrderSimulation: undefined,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.SALE_ORDER_SIMULATION:
			return {
				...state,
				saleOrderSimulation: action.payload.saleOrderSimulation,
			};
		default:
			throw new Error();
	}
};

const ProfitabilityAnalysisEditActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): IProfitabilityAnalysisEditActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getSaleOrderSimulationById(id: string) {
			actions.setLoading(true);

			getSaleOrderSimulationById(id)
				.then((response) => {
					dispatch({
						type: ActionType.SALE_ORDER_SIMULATION,
						payload: { saleOrderSimulation: response.data },
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Erro ao carregar a simulação do pedido de venda.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		updateSaleOrderSimulation(id: string, data: ISaleOrderSimulation) {
			actions.setLoading(true);

			updateSaleOrderSimulation(id, data)
				.then((response) => {
					enqueueSnackbar(response?.data.message, {
						variant: 'success',
					});
					navigate(`/sale-order-simulation/${id}`);
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Erro ao atualizar a simulação do pedido de venda.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		updateSaleOrderSimulationItem(
			simulationId: string,
			itemId: string,
			data: ISaleOrderSimulationItem,
		) {
			actions.setLoading(true);

			updateSaleOrderSimulationItem(itemId, data)
				.then((response) => {
					enqueueSnackbar(response.data.message, {
						variant: 'success',
					});
					actions.getSaleOrderSimulationById(simulationId);
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message
					|| 'Erro ao atualizar o item da simulação.',
						{
							variant: 'error',
						},
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		upsertIndirectCostToFormation(data: IIndirectCostSalePriceFormation) {
			actions.setLoading(true);
			upsertIndirectCostToFormation(data)
				.then((response) => {
					enqueueSnackbar(response.data.message, { variant: 'success' });
				})
				.catch((error) => {
					enqueueSnackbar(error.response?.data.message || 'Erro ao adicionar custo indireto.', { variant: 'error' });
				})
				.finally(() => actions.setLoading(false));
		},
		updateSelectedRangeSimulation(
			rangeCommissionId: string,
			salePriceFormationId: string,
			selectedRangeSimulation: boolean,
		) {
			actions.setLoading(true);

			updateSelectedRangeSimulation(
				rangeCommissionId,
				salePriceFormationId,
				selectedRangeSimulation,
			)
				.then((response) => {
					enqueueSnackbar(response.data.message, { variant: 'success' });
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Erro ao atualizar a faixa de comissão selecionada.', {
						variant: 'error',
					});
				})
				.finally(() => actions.setLoading(false));
		},
		updateSelectedAveragePaymentSimulation(
			averagePaymentTermId: string,
			salePriceFormationId: string,
			selectedAveragePaymentSimulation: boolean,
		) {
			actions.setLoading(true);

			updateSelectedAveragePaymentSimulation(
				averagePaymentTermId,
				salePriceFormationId,
				selectedAveragePaymentSimulation,
			)
				.then((response) => {
					enqueueSnackbar(response.data.message, { variant: 'success' });
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Erro ao atualizar o termo de pagamento selecionado.', { variant: 'error' });
				})
				.finally(() => actions.setLoading(false));
		},
	};

	return actions;
};

const ProfitabilityAnalysisEditContainer = (): JSX.Element | null => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const actions = useMemo(
		() => ProfitabilityAnalysisEditActions(dispatch, enqueueSnackbar, navigate),
		[enqueueSnackbar, navigate],
	);

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<ProfitabilityAnalysisEditPresentational {...state} {...actions} />
	);
};

export default ProfitabilityAnalysisEditContainer;
