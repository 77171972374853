import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Grid,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	FormControl,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useFormikContext } from 'formik';
import Decimal from 'decimal.js';
import {
	IRangeCommission,
	ICreateRangeCommission,
	IRangeCommissionSalePriceFormation,
	IExtendedSalePriceFormation,
} from '../../../containers/SalePriceFormation/SalePriceFormationAssets';
import Input from '../../Common/Form/Input';
import Autocomplete from '../../Common/Form/Autocomplete';
import ModalForm from '../Modals/ModalForm';

interface RangeCommissionFormProps {
	isReadOnly: boolean;
	rangeCommissions?: IRangeCommission[];
	createRangeCommission: (data: ICreateRangeCommission) => void;
	upsertRangeCommissionToFormation(data: IRangeCommissionSalePriceFormation): void;
	removeRangeCommissionFromFormation(rangeCommissionId: string, salePriceFormationId: string): void;
}

const RangeCommissionForm = ({
	isReadOnly,
	rangeCommissions,
	createRangeCommission,
	upsertRangeCommissionToFormation,
	removeRangeCommissionFromFormation,
}: RangeCommissionFormProps): JSX.Element => {
	const { values, setFieldValue } = useFormikContext<IExtendedSalePriceFormation>();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	useEffect(() => {
		const newIndexes: { [key: string]: string } = {};
		values.rangeCommissionSalePriceFormations?.forEach((rangeCommission) => {
			const indexValue = new Decimal(1).div(
				new Decimal(1).minus(
					new Decimal(rangeCommission.commissionRate).div(100)
						.plus(new Decimal(values?.totalIndirectCost || 0).div(100)),
				),
			).toFixed(4).replace('.', ',');
			newIndexes[rangeCommission.commissionRate] = indexValue;
			if (Number(rangeCommission.commissionRate) === Number(values.baseCommission)
				&& values.baseCommission) {
				setFieldValue('indexValue', indexValue.replace(',', '.'));
			}
		});
		setFieldValue('indexValues', newIndexes);
	}, [
		setFieldValue,
		values.baseCommission,
		values.rangeCommissionSalePriceFormations,
		values?.totalIndirectCost,
	]);

	const handleAddCommission = useCallback((): void => {
		const selectedRangeCommission = rangeCommissions?.find(
			(rangeCommission) => rangeCommission.id === values.currentRangeCommissionId,
		);

		if (
			selectedRangeCommission
			&& selectedRangeCommission.range !== undefined
			&& values.id
			&& values.currentRangeCommissionRate !== undefined
		) {
			const newRangeCommission: IRangeCommissionSalePriceFormation = {
				rangeCommissionId: selectedRangeCommission.id,
				salePriceFormationId: values.id,
				commissionRate: values.currentRangeCommissionRate,
				range: selectedRangeCommission.range,
				description: selectedRangeCommission.description,
			};

			// const newRangeCommissionSalePriceFormations = [
			// 	...(values.rangeCommissionSalePriceFormations || []),
			// 	newRangeCommission,
			// ];

			upsertRangeCommissionToFormation(newRangeCommission);

			// newRangeCommissionSalePriceFormations.sort((a, b) => a.range - b.range);
			// setFieldValue('rangeCommissionSalePriceFormations', newRangeCommissionSalePriceFormations);
		}
		setFieldValue('currentRangeCommissionRate', '');
	}, [
		rangeCommissions,
		values.id,
		values.currentRangeCommissionRate,
		values.currentRangeCommissionId,
		upsertRangeCommissionToFormation,
		setFieldValue,
	]);

	// const handleDeleteRangeCommission = useCallback((rangeCommissionId: string): void => {
	// 	const updatedRangeCommissions = values.rangeCommissionSalePriceFormations?.filter(
	// 		(rangeCommission) => rangeCommission.rangeCommissionId !== rangeCommissionId,
	// 	);
	// 	setFieldValue('rangeCommissionSalePriceFormations', updatedRangeCommissions);
	// }, [values.rangeCommissionSalePriceFormations, setFieldValue]);

	const handleOpenModal = useCallback((): void => {
		setIsModalOpen(true);
	}, []);

	const handleCloseModal = useCallback((): void => {
		setIsModalOpen(false);
		setFieldValue('newRangeCommissionRate', '');
		setFieldValue('newRange', '');
	}, [setFieldValue]);

	const handleCreateNewCommission = useCallback(() => {
		if (values.newRangeCommissionRate) {
			createRangeCommission({
				range: Number(values.newRange),
				commissionRate: parseFloat(values.newRangeCommissionRate),
			});
			handleCloseModal();
		}
	}, [values, createRangeCommission, handleCloseModal]);

	const handleCommissionInputChange = useCallback((
		event: any,
		value: string,
		reason: string,
	): void => {
		if (reason === 'reset') {
			const selectedRangeCommission = rangeCommissions?.find((rangeCommission) => `${rangeCommission.description} - ${rangeCommission.range}` === value);
			if (selectedRangeCommission) {
				setFieldValue('currentRangeCommissionId', selectedRangeCommission.id);
				setFieldValue('currentRangeCommissionRate', selectedRangeCommission.commissionRate);
			}
		} else if (reason === 'clear') {
			setFieldValue('currentRangeCommissionId', '');
			setFieldValue('currentRangeCommissionRate', '');
			setFieldValue('currentRange', '');
		}
	}, [rangeCommissions, setFieldValue]);

	const handleBlur = useCallback((event, rangeCommission) => {
		const { value } = event.target;
		if (!value) return;

		const updatedRangeCommissions = values.rangeCommissionSalePriceFormations?.map(
			(existingRangeCommission) => (existingRangeCommission.rangeCommissionId
				=== rangeCommission.rangeCommissionId
				? { ...existingRangeCommission, commissionRate: value }
				: existingRangeCommission),
		);
		if (updatedRangeCommissions) {
			const updatedRangeCommission = updatedRangeCommissions
				.find((commission) => commission.rangeCommissionId
				=== rangeCommission.rangeCommissionId);
			if (updatedRangeCommission) {
				upsertRangeCommissionToFormation(updatedRangeCommission);
			}
		}
		// setFieldValue('rangeCommissionSalePriceFormations', updatedRangeCommissions);
	}, [values.rangeCommissionSalePriceFormations, upsertRangeCommissionToFormation]);

	const isRangeCommissionAdded = useCallback((
		rangeCommissionId: string,
	) => values.rangeCommissionSalePriceFormations?.some(
		(rangeCommission) => rangeCommission.rangeCommissionId === rangeCommissionId,
	), [values.rangeCommissionSalePriceFormations]);

	const modalMemo = useMemo(() => (
		<ModalForm
			isOpen={isModalOpen}
			onClose={handleCloseModal}
			title="Comissão Padrão"
			fields={[
				{ name: 'newRange', label: 'Comissão' },
				{
					name: 'newRangeCommissionRate',
					label: 'Porcentagem',
					type: 'number',
					adornment: '%',
				},
			]}
			onConfirm={handleCreateNewCommission}
		/>
	), [isModalOpen, handleCloseModal, handleCreateNewCommission]);

	const availableRangeCommissions = useMemo(() => rangeCommissions?.filter(
		(rangeCommission) => !isRangeCommissionAdded(rangeCommission.id),
	) || [], [rangeCommissions, isRangeCommissionAdded]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TableContainer component={Paper} sx={{ maxHeight: '322px' }}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell sx={{ width: '25%' }}>Comissão (%)</TableCell>
								<TableCell sx={{ width: '25%%' }}>Índice</TableCell>
								<TableCell align="right" sx={{ width: '25%' }}>(%)</TableCell>
								<TableCell align="right" sx={{ width: '25%' }}>Ações</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{values.rangeCommissionSalePriceFormations?.map((rangeCommission, index) => (
								<TableRow key={rangeCommission.rangeCommissionId}>
									<TableCell sx={{ padding: '6px 16px' }}>
										{rangeCommission.range}
									</TableCell>
									<TableCell sx={{ padding: '6px 16px' }}>
										{values.indexValues?.[rangeCommission.commissionRate]}
									</TableCell>
									<TableCell align="right" sx={{ padding: '6px 16px' }}>
										<Input.InputField
											name={`rangeCommissionSalePriceFormations[${index}].commissionRate`}
											type="number"
											fullWidth
											margin="dense"
											sx={{
												height: '30px',
												margin: 0,
												'& .MuiInputBase-input': {
													height: '30px',
													padding: '0 5px',
												},
											}}
											InputProps={{
												endAdornment: '%',
												inputProps: {
													min: 0,
													step: 'any',
												},
											}}
											onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
												let { value } = event.target;
												value = value.replace(',', '.');
												if (value === '' || Number.isNaN(Number(value)) || !Number.isFinite(Number(value))) {
													value = '0';
												} else {
													value = value.replace(/^0+(?=\d)/, '');
												}

												setFieldValue(`rangeCommissionSalePriceFormations[${index}].commissionRate`, value);
											}}
											onBlur={(event) => handleBlur(event, rangeCommission)}
											disabled={isReadOnly}
										/>
									</TableCell>
									<TableCell align="right" sx={{ padding: '6px 16px' }}>
										<IconButton onClick={() => removeRangeCommissionFromFormation(rangeCommission.rangeCommissionId, rangeCommission.salePriceFormationId)} color="error" disabled={isReadOnly}>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			<Grid item xs={12} md={6}>
				<FormControl fullWidth margin="dense">
					<Autocomplete
						name="currentRange"
						label="Comissão"
						options={availableRangeCommissions || []}
						labelKey="range"
						valueKey="range"
						valueLabel="description"
						onInputChange={handleCommissionInputChange}
						closeOnSelect
						disabled={isReadOnly}
					/>
				</FormControl>
			</Grid>
			<Grid item xs={12} md={6}>
				<Input.InputField
					fullWidth
					label="(%)"
					name="currentRangeCommissionRate"
					variant="outlined"
					margin="dense"
					type="number"
					InputProps={{ endAdornment: '%' }}
					InputLabelProps={{ shrink: true }}
					disabled={isReadOnly}
				/>
			</Grid>
			<Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Button variant="contained" onClick={handleAddCommission} color="primary" disabled={isReadOnly}>
					Adicionar
				</Button>
				<Button variant="outlined" onClick={handleOpenModal} startIcon={<AddIcon />} disabled={isReadOnly}>
					Comissão Padrão
				</Button>
			</Grid>
			{modalMemo}
		</Grid>
	);
};

RangeCommissionForm.defaultProps = {
	rangeCommissions: undefined,
};

export default RangeCommissionForm;
