import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	useSnackbar,
} from 'notistack';
import { useFormik, FormikContext, Form } from 'formik';
import {
	Typography,
	Paper,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Box,
	Card,
	CardContent,
	Grid,
	SelectChangeEvent,
} from '@mui/material';
import { Calculate as CalculateIcon } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Decimal from 'decimal.js';
import IndirectCostForm from './Forms/IndirectCostForm';
import DirectCostForm from './Forms/DirectCostForm';
import RangePriceCommissionTable from './Forms/RangePriceCommissionTable';
import { currencyBRLMask } from '../../helpers/intl';
import { formatCode } from '../../helpers/Utils';
import AveragePaymentTermForm from './Forms/AveragePaymentTermForm';
import {
	IAveragePaymentTerm,
	IAveragePaymentTermSalePriceFormation,
	ICostBaseMonth,
	ICreateAveragePaymentTerm,
	ICreateIndirectCost,
	ICreateRangeCommission,
	IExtendedSalePriceFormation,
	IIndirectCost,
	initialValues,
	IRangeCommission,
	IRangeCommissionSalePriceFormation,
	ISalePriceFormation,
	ISalePriceFormationItem,
} from '../../containers/SalePriceFormation/SalePriceFormationAssets';
import Select from '../Common/Form/Select';
import RangeCommissionForm from './Forms/RangeCommissionForm';
import Input from '../Common/Form/Input';
import { SalePriceFormationStatus } from '../../enums/SalePriceFormationStatus';
import ModalForm from './Modals/ModalForm';
import ActionJustificativeModal from '../Common/ActionJustificativeModal';
import { monthMap } from '../../constants/salePriceFormationColumns';
import usePermission from '../../hooks/usePermission';

interface SalesPriceFormationFormProps {
	loading: boolean;
	isReadOnly: boolean;
	onSubmit: (values: IExtendedSalePriceFormation, status: SalePriceFormationStatus) => void;
	salePriceFormation?: ISalePriceFormation;
	costBaseMonths?: ICostBaseMonth[];
	indirectCosts?: IIndirectCost[];
	averagePaymentTerms?: IAveragePaymentTerm[];
	rangeCommissions?: IRangeCommission[];
	items?: ISalePriceFormationItem[];
	averagePaymentTermSalePriceFormations?: IAveragePaymentTermSalePriceFormation[];
	rangeCommissionSalePriceFormations?: IRangeCommissionSalePriceFormation[];
	handleCostBaseMonthChange: (event: SelectChangeEvent) => void;
	createIndirectCost(data: ICreateIndirectCost): void;
	createAveragePaymentTerm(data: ICreateAveragePaymentTerm): void;
	createRangeCommission(data: ICreateRangeCommission): void;
	upsertAveragePaymentTermToFormation(data: IAveragePaymentTermSalePriceFormation): void;
	removeAveragePaymentTermFromFormation(id: string, salePriceFormationId: string): void;
	upsertRangeCommissionToFormation(data: IRangeCommissionSalePriceFormation): void;
	removeRangeCommissionFromFormation(rangeCommissionId: string, salePriceFormationId: string): void;
}

const aliases: { [key: string]: string } = {
	A: 'totalDirectCost',
	B: 'baseCommission',
	C: 'averageTerm',
	D: 'totalIndirectCost',
	E: 'indexValue',
	F: 'discountIndex',
};

const SalePriceFormationForm = ({
	loading,
	isReadOnly,
	onSubmit,
	salePriceFormation,
	costBaseMonths,
	indirectCosts,
	averagePaymentTerms,
	rangeCommissions,
	items,
	averagePaymentTermSalePriceFormations,
	rangeCommissionSalePriceFormations,
	handleCostBaseMonthChange,
	createIndirectCost,
	createAveragePaymentTerm,
	createRangeCommission,
	upsertAveragePaymentTermToFormation,
	removeAveragePaymentTermFromFormation,
	upsertRangeCommissionToFormation,
	removeRangeCommissionFromFormation,
}: SalesPriceFormationFormProps): JSX.Element => {
	const { enqueueSnackbar } = useSnackbar();
	const [isModalOpen, setModalOpen] = useState(false);
	const [isJustificativeModalOpen, setJustificativeModalOpen] = useState(false);
	const permissions = usePermission('SALE_PRICE_FORMATION');
	const isAdmin = permissions?.isAdmin || false;
	const formik = useFormik<IExtendedSalePriceFormation>({
		initialValues: {
			...initialValues,
			...salePriceFormation,
			formula: salePriceFormation?.formula || '((A*(E))*((F/100)+1))',
		},
		onSubmit: (values) => {
			onSubmit(values, values.status);
		},
		enableReinitialize: true,
	});

	useEffect(() => {
		if (items) {
			const currentItems = formik.values.items || [];
			const mergedItems = items.map((newItem) => {
				const existingItem = currentItems.find((item) => item.id === newItem.id);
				return existingItem
					? { ...existingItem, ...newItem, quantity: newItem.quantity }
					: newItem;
			});
			formik.setFieldValue('items', mergedItems);
		}
		if (averagePaymentTermSalePriceFormations) {
			formik.setFieldValue('averagePaymentTermSalePriceFormations', averagePaymentTermSalePriceFormations);
		}

		if (rangeCommissionSalePriceFormations) {
			formik.setFieldValue('rangeCommissionSalePriceFormations', rangeCommissionSalePriceFormations);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		items,
		averagePaymentTermSalePriceFormations,
		rangeCommissionSalePriceFormations,
	]);

	useEffect(() => {
		const discount = formik.values.priceListStateSalePriceFormation?.discount || 0;
		if (discount !== undefined && discount !== null) {
			const calculatedIndex = new Decimal(discount)
				.dividedBy(new Decimal(100).minus(discount))
				.times(100)
				.toDecimalPlaces(3)
				.toNumber();
			formik.setFieldValue('discountIndex', calculatedIndex);
		} else {
			formik.setFieldValue('discountIndex', 0);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.priceListStateSalePriceFormation?.discount]);

	const handleSaveClick = useCallback(() => {
		formik.handleSubmit();
	}, [formik]);

	const handleApproveClick = useCallback(() => {
		formik.setFieldValue('status', SalePriceFormationStatus.APPROVED);
		setModalOpen(true);
	}, [formik]);

	const handleRejectClick = useCallback(() => {
		setJustificativeModalOpen(true);
	}, []);

	const handleCreateNewRevision = useCallback(() => {
		formik.setFieldValue('id', undefined);
		formik.handleSubmit();
	}, [formik]);

	const handleJustificativeSubmit = useCallback((justificative: string) => {
		formik.setFieldValue('actionJustificative', justificative);
		formik.setFieldValue('status', SalePriceFormationStatus.REJECTED);
		formik.handleSubmit();
		setJustificativeModalOpen(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleModalConfirm = useCallback(() => {
		formik.handleSubmit();
		setModalOpen(false);
	}, [formik]);

	const formattedCostBaseMonths = useMemo(() => {
		const result = costBaseMonths?.map(({ month, year }) => {
			const formattedDate = `${year}-${String(monthMap[month] + 1).padStart(2, '0')}-01`;
			const formatted = {
				label: `${month}/${year}`,
				value: formattedDate,
			};
			return formatted;
		});
		return result;
	}, [costBaseMonths]);

	const commissionOptions = useMemo(() => formik.values
		?.rangeCommissionSalePriceFormations?.map((commission) => ({
			label: `${commission.description} ${commission.range} - ${commission.commissionRate}%`,
			value: commission.commissionRate,
		})) || [], [formik.values?.rangeCommissionSalePriceFormations]);

	const paymentTermOptions = useMemo(() => formik.values
		?.averagePaymentTermSalePriceFormations?.map((term) => ({
			label: `${term.description} - ${term.interestRate}%`,
			value: `${term.id}`,
		})) || [], [formik.values?.averagePaymentTermSalePriceFormations]);

	const calculateFormula = useCallback(() => {
		const {
			formula,
			averageTermId,
			baseCommission,
		} = formik.values;

		if (!formula) {
			enqueueSnackbar('Fórmula não definida', { variant: 'error' });
			return;
		}

		if (!baseCommission) {
			enqueueSnackbar('Escolha uma Comissão Base (B) para calcular o preço de venda', {
				variant: 'error',
			});
			return;
		}

		if (!averageTermId) {
			enqueueSnackbar('Escolha um Prazo Médio (C) para calcular o preço de venda', {
				variant: 'error',
			});
			return;
		}

		try {
			const vistaTermId = averagePaymentTermSalePriceFormations
				?.find((term) => {
					const normalizedDescription = (term.description ?? '').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
					return ['a vista', 'à vista', 'avista', 'a vista', 'vista'].includes(normalizedDescription);
				})?.id;

			const vistaTerm = averagePaymentTermSalePriceFormations
				?.find((term) => term.id === vistaTermId);
			const vistaInterestRate = vistaTerm ? vistaTerm.interestRate : 0;

			const replacedFormulaVista = Object.keys(aliases).reduce((acc, key) => {
				let value;
				if (key === 'C') {
					value = vistaInterestRate;
				} else {
					value = formik.values[aliases[key] as keyof ISalePriceFormation];
				}
				return acc.replace(new RegExp(key, 'g'), value !== undefined ? value.toString() : '0');
			}, formula);

			// eslint-disable-next-line no-eval
			const resultVista = eval(replacedFormulaVista.replace(/\\/g, '/'));

			const selectedTerm = averagePaymentTermSalePriceFormations
				?.find((term) => term.id === averageTermId);
			const selectedInterestRate = selectedTerm ? selectedTerm.interestRate : 0;

			let finalResult = resultVista;

			if (selectedTerm?.id !== vistaTermId) {
				finalResult = resultVista * (1 + selectedInterestRate / 100);
			}

			formik.setFieldValue('salePrice', finalResult);
			enqueueSnackbar('Fórmula calculada com sucesso', { variant: 'success' });
		} catch (error) {
			enqueueSnackbar('Erro ao calcular a fórmula', { variant: 'error' });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		formik.values.formula,
		formik.values.baseCommission,
		formik.values.totalDirectCost,
		formik.values.totalIndirectCost,
		formik.values.indexValue,
		formik.values.discountIndex,
		formik.values.averageTermId,
		formik.values.averagePaymentTermSalePriceFormations,
	]);

	const directCostFormMemo = useMemo(() => (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant="h6">Custos Diretos</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<DirectCostForm isReadOnly={isReadOnly} />
			</AccordionDetails>
		</Accordion>
	), [isReadOnly]);

	const indirectCostFormMemo = useMemo(() => (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant="h6">Custos Indiretos</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<IndirectCostForm
					isReadOnly={isReadOnly}
					indirectCosts={indirectCosts}
					createIndirectCost={createIndirectCost}
				/>
			</AccordionDetails>
		</Accordion>
	), [isReadOnly, indirectCosts, createIndirectCost]);

	const averagePaymentTermFormMemo = useMemo(() => (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant="h6">Prazo Médio</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<AveragePaymentTermForm
					isReadOnly={isReadOnly}
					averagePaymentTerms={averagePaymentTerms}
					createAveragePaymentTerm={createAveragePaymentTerm}
					upsertAveragePaymentTermToFormation={upsertAveragePaymentTermToFormation}
					removeAveragePaymentTermFromFormation={removeAveragePaymentTermFromFormation}
				/>
			</AccordionDetails>
		</Accordion>
	), [
		isReadOnly,
		averagePaymentTerms,
		createAveragePaymentTerm,
		removeAveragePaymentTermFromFormation,
		upsertAveragePaymentTermToFormation,
	]);

	const rangeCommissionFormMemo = useMemo(() => (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant="h6">Faixas</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<RangeCommissionForm
					isReadOnly={isReadOnly}
					rangeCommissions={rangeCommissions}
					createRangeCommission={createRangeCommission}
					upsertRangeCommissionToFormation={upsertRangeCommissionToFormation}
					removeRangeCommissionFromFormation={removeRangeCommissionFromFormation}
				/>
			</AccordionDetails>
		</Accordion>
	), [
		isReadOnly,
		rangeCommissions,
		createRangeCommission,
		upsertRangeCommissionToFormation,
		removeRangeCommissionFromFormation,
	]);

	const modalMemo = useMemo(() => (
		<ModalForm
			isOpen={isModalOpen}
			onClose={() => setModalOpen(false)}
			title="Data de ínicio da Vigência"
			fields={[{ name: 'validity', label: 'Data de Vigência', type: 'date' }]}
			onConfirm={handleModalConfirm}
		/>
	), [isModalOpen, handleModalConfirm]);

	return (
		<FormikContext.Provider value={formik}>
			<Form onSubmit={formik.handleSubmit}>
				<Card sx={{ mb: 3 }}>
					<CardContent>
						<Grid container spacing={2} alignItems="center">
							<Grid item xs={4} sm={4} md={4}>
								<Box display="flex" flexDirection="column" alignItems="center" sx={{ width: '100%' }}>
									<Typography variant="subtitle1" sx={{ fontWeight: 450 }}>
										Form. PV
									</Typography>
									<Typography variant="body1">
										{formatCode(formik.values.code)}
										/
										{formik.values.revision}
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={4} sm={4} md={4}>
								<Box display="flex" flexDirection="column" alignItems="center" sx={{ width: '100%' }}>
									<Typography variant="subtitle1" sx={{ fontWeight: 450 }}>
										Produto
									</Typography>
									<Typography variant="body1" textAlign="center">
										{formik.values.productCode}
										{' '}
										-
										{' '}
										{formik.values.productDescription}
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={4} sm={4} md={4}>
								<Box display="flex" flexDirection="column" alignItems="center" sx={{ width: '100%' }}>
									<Typography variant="subtitle1" sx={{ fontWeight: 450 }}>
										Preço de Venda
									</Typography>
									<Typography variant="body1">{`R$ ${(formik.values.salePrice ? Number(formik.values.salePrice).toFixed(3).replace('.', ',') : '0,000')}`}</Typography>
								</Box>
							</Grid>
							<Grid item xs={4} sm={4} md={4}>
								<Box display="flex" flexDirection="column" alignItems="center" sx={{ width: '100%' }}>
									<Typography variant="subtitle1" sx={{ fontWeight: 450 }}>
										Custos Indiretos (D)
									</Typography>
									<Typography variant="body1">
										{Number(formik.values.totalIndirectCost).toFixed(3).replace('.', ',')}
										%
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={4} sm={4} md={4}>
								<Box display="flex" flexDirection="column" alignItems="center" sx={{ width: '100%' }}>
									{!isAdmin && (
										<Typography variant="subtitle1" sx={{ fontWeight: 450 }}>
											Custos Diretos (A)
										</Typography>
									)}
									<Box>
										{isAdmin ? (
											<Input.FinancialInputField
												name="totalDirectCost"
												label="Custos Diretos (A)"
												variant="outlined"
												margin="dense"
												value={formik.values.totalDirectCost || 0}
												onChange={formik.handleChange}
												decimalScale={3}
											/>
										) : (
											`${formik.values.totalDirectCost ? currencyBRLMask(formik.values.totalDirectCost, { decimalPlaces: 3 }) : '0,000'}`
										)}
									</Box>
								</Box>
							</Grid>
							<Grid item xs={4} sm={4} md={4}>
								<Box display="flex" flexDirection="column" alignItems="center" sx={{ width: '100%' }}>
									<Typography variant="subtitle1" sx={{ fontWeight: 450 }}>
										Margem Bruta
									</Typography>
									<Typography variant="body1">
										{formik.values.salePrice && formik.values.salePrice !== 0
											? `${new Decimal(formik.values.totalDirectCost)
												.div(formik.values.salePrice)
												.mul(100)
												.toFixed(3)
												.replace('.', ',')}%`
											: '0,000%'}
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={2} sm={2} md={2}>
								<Box display="flex" flexDirection="column" alignItems="center" sx={{ width: '100%' }}>
									<Select
										name="costBaseMonth"
										label="Base Custo Médio"
										options={formattedCostBaseMonths || []}
										labelKey="label"
										valueKey="value"
										value={formik.values.costBaseMonth || ''}
										onChange={handleCostBaseMonthChange}
										disabled={isReadOnly}
									/>
								</Box>
							</Grid>
							<Grid item xs={1} sm={1} md={1}>
								<Box display="flex" flexDirection="column" alignItems="center" sx={{ width: '100%', marginBottom: 0.5 }}>
									<Input.InputField
										name="priceListStateSalePriceFormation.priceListState.priceList"
										label="Tabela"
										variant="outlined"
										margin="dense"
										InputProps={{
											readOnly: true,
										}}
										disabled={isReadOnly}
									/>
								</Box>
							</Grid>
							<Grid item xs={1} sm={1} md={1}>
								<Box display="flex" flexDirection="column" alignItems="center" sx={{ width: '100%', marginBottom: 0.5 }}>
									<Input.InputField
										name="priceListStateSalePriceFormation.discount"
										label="Desconto"
										variant="outlined"
										margin="dense"
										type="number"
										InputProps={{
											endAdornment: '%',
										}}
										disabled={isReadOnly}
									/>
								</Box>
							</Grid>
							<Grid item xs={2} sm={2} md={2}>
								<Box display="flex" flexDirection="column" alignItems="center" sx={{ width: '100%', marginBottom: 0.5 }}>
									<Input.InputField
										name="discountIndex"
										label="Indice Desconto (F)"
										variant="outlined"
										margin="dense"
										type="number"
										InputProps={{
											readOnly: true,
										}}
										disabled={isReadOnly}
									/>
								</Box>
							</Grid>
							<Grid item xs={2} sm={2} md={2}>
								<Box display="flex" flexDirection="column" alignItems="center" sx={{ width: '100%' }}>
									<Select
										name="baseCommission"
										label="Comissão Base (B)"
										options={commissionOptions || []}
										labelKey="label"
										valueKey="value"
										disabled={isReadOnly}
									/>
								</Box>
							</Grid>
							<Grid item xs={2} sm={2} md={2}>
								<Box display="flex" flexDirection="column" alignItems="center" sx={{ width: '100%', marginBottom: 0.5 }}>
									<Input.InputField
										name="indexValue"
										label="Indice Financeiro (E)"
										variant="outlined"
										margin="dense"
										value={formik.values.indexValue || 0}
										InputProps={{
											readOnly: true,
										}}
										disabled={isReadOnly}
									/>
								</Box>
							</Grid>
							<Grid item xs={2} sm={2} md={2}>
								<Box display="flex" flexDirection="column" alignItems="center" sx={{ width: '100%' }}>
									<Select
										name="averageTermId"
										label="Prazo Médio (C)"
										options={paymentTermOptions}
										labelKey="label"
										valueKey="value"
										onChange={(event) => {
											const selectedTermId = event.target.value;
											const selectedInterestRate = salePriceFormation
												?.averagePaymentTermSalePriceFormations
												?.find((term) => term.id === selectedTermId)?.interestRate || 0;
											formik.setFieldValue('averageTerm', selectedInterestRate);
										}}
										disabled={isReadOnly}
									/>
								</Box>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<Box display="flex" alignItems="center" sx={{ width: '100%' }}>
									<Input.InputField
										name="formula"
										label="Formula"
										variant="outlined"
										margin="dense"
										disabled={isReadOnly || !isAdmin}
									/>
									<Button
										variant="contained"
										color="primary"
										startIcon={<CalculateIcon />}
										sx={{ ml: 2, height: '56px' }}
										onClick={calculateFormula}
										disabled={isReadOnly || !formik.values.formula}
									>
										Calcular
									</Button>
								</Box>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
				<Paper sx={{ mt: 3, p: 2 }} elevation={3}>
					<Grid container spacing={2}>
						{isAdmin && (
							<Grid item xs={12}>
								{directCostFormMemo}
							</Grid>
						)}
						<Grid item xs={4}>
							{indirectCostFormMemo}
						</Grid>
						<Grid item xs={4}>
							{averagePaymentTermFormMemo}
						</Grid>
						<Grid item xs={4}>
							{rangeCommissionFormMemo}
						</Grid>
						<Grid item xs={12}>
							<Accordion>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography variant="h6">Faixa/Preço/Comissão</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<RangePriceCommissionTable />
								</AccordionDetails>
							</Accordion>
						</Grid>
						<Grid item xs={12}>
							{isReadOnly ? (
								<Grid item xs={2}>
									<Button variant="contained" color="primary" disabled={!isAdmin} onClick={handleCreateNewRevision} fullWidth>
										CRIAR NOVA REVISÃO
									</Button>
								</Grid>
							) : (
								<Grid container spacing={2}>
									<Grid item xs={1}>
										<Button variant="contained" color="primary" disabled={loading} onClick={handleSaveClick} fullWidth>
											Salvar
										</Button>
									</Grid>
									<Grid item xs={1}>
										<Button variant="contained" color="primary" disabled={loading || !formik.values.salePrice} onClick={handleApproveClick} fullWidth>
											Aprovar
										</Button>
									</Grid>
									<Grid item xs={1}>
										<Button
											variant="contained"
											color="primary"
											disabled={loading || !formik.values.salePrice}
											onClick={handleRejectClick}
											fullWidth
										>
											Reprovar
										</Button>
									</Grid>
								</Grid>
							)}
						</Grid>
					</Grid>
					{modalMemo}
					<ActionJustificativeModal
						title="Justificativa para Reprovação"
						action={handleJustificativeSubmit}
						open={isJustificativeModalOpen}
						onClose={() => setJustificativeModalOpen(false)}
					/>
				</Paper>
			</Form>
		</FormikContext.Provider>
	);
};

SalePriceFormationForm.defaultProps = {
	salePriceFormation: undefined,
	costBaseMonths: undefined,
	indirectCosts: undefined,
	averagePaymentTerms: undefined,
	rangeCommissions: undefined,
	items: undefined,
	averagePaymentTermSalePriceFormations: undefined,
	rangeCommissionSalePriceFormations: undefined,
};

export default SalePriceFormationForm;
