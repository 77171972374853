import { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';

export function useFormikValuesUpdater(
	fieldValues: Record<string, any>,
	dependencies: any[],
): void {
	const { setFieldValue } = useFormikContext();
	const previousValues = useRef<Record<string, any>>({});

	useEffect(() => {
		Object.entries(fieldValues).forEach(([field, value]) => {
			const previousValue = previousValues.current[field];
			if (!isEqual(previousValue, value)) {
				setFieldValue(field, value, false);
				previousValues.current[field] = value;
			}
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fieldValues, setFieldValue, ...dependencies]);
}
