import React, { useMemo, useState, useCallback } from 'react';
import {
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Box,
	Tooltip,
	IconButton,
} from '@mui/material';
import { useFormikContext } from 'formik';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { initialSaleOrderSimulationItem, initialSalePriceFormationAnalysis, ISaleOrderSimulation } from '../../../containers/ProfitabilityAnalysis/ProfitabilityAnalysisAssets';
import { currencyBRLMask } from '../../../helpers/intl';
import {
	calculateUnitValueAverageTerm,
	getSalePriceFormation,
	getSelectedItem,
	getSelectedItemIndex,
} from '../../../helpers/ProfitabilityAnalysisCalculations';
import { useFormikValuesUpdater } from '../../../hooks/useFormikValuesUpdater';
import { ProfitabilityAnalysisStatus } from '../../../enums/ProfitabilityAnalysisStatus';

interface IAveragePaymentTermSimulationTableProps {
	updateSelectedAveragePaymentSimulation(
		averagePaymentTermId: string,
		salePriceFormationId: string,
		selectedAveragePaymentSimulation: boolean,
	): void;
}

const AveragePaymentTermSimulationTable = ({
	updateSelectedAveragePaymentSimulation,
}: IAveragePaymentTermSimulationTableProps): JSX.Element => {
	const { values, setFieldValue } = useFormikContext<ISaleOrderSimulation>();
	const [showExtraColumns, setShowExtraColumns] = useState(true);

	const selectedItem = useMemo(
		() => getSelectedItem(
			values.saleOrderSimulationItems,
			initialSaleOrderSimulationItem,
		),
		[values.saleOrderSimulationItems],
	);

	const selectedItemIndex = useMemo(
		() => getSelectedItemIndex(values.saleOrderSimulationItems),
		[values.saleOrderSimulationItems],
	);

	const salePriceFormation = useMemo(
		() => getSalePriceFormation(selectedItem, initialSalePriceFormationAnalysis),
		[selectedItem],
	);

	const isReadOnly = useMemo(
		() => [
			ProfitabilityAnalysisStatus.APPROVED,
			ProfitabilityAnalysisStatus.REJECTED,
		].includes(values.status),
		[values.status],
	);

	const options = useMemo(() => {
		if (!selectedItem || !salePriceFormation.averagePaymentTermSalePriceFormations) return [];

		return salePriceFormation.averagePaymentTermSalePriceFormations
			.map((term: any) => {
				const unitValueAverageTerm = calculateUnitValueAverageTerm(
					selectedItem.salePriceFormation,
					term,
				);

				return {
					id: term.averagePaymentTermId,
					value: term.averagePaymentTerm?.code.toString(),
					label: term.averagePaymentTerm?.description,
					interestRate: term.interestRate.toFixed(3),
					unitValueAverageTerm,
					selectedAveragePaymentSimulation: term.selectedAveragePaymentSimulation,
					averagePaymentTermId: term.averagePaymentTermId,
				};
			});
	}, [salePriceFormation.averagePaymentTermSalePriceFormations, selectedItem]);

	const handleRowClick = useCallback(
		(term: any, index: number): void => {
			if (isReadOnly) return;

			const updatedTerms = salePriceFormation.averagePaymentTermSalePriceFormations
				?.map((t: any, i) => ({
					...t,
					selectedAveragePaymentSimulation: i === index,
				}));

			setFieldValue(
				`saleOrderSimulationItems[${selectedItemIndex}].salePriceFormation.averagePaymentTermSalePriceFormations`,
				updatedTerms,
			);

			if (salePriceFormation?.id) {
				updateSelectedAveragePaymentSimulation(
					term.averagePaymentTermId,
					salePriceFormation.id,
					true,
				);
			}
		},
		[
			isReadOnly,
			salePriceFormation,
			selectedItemIndex,
			setFieldValue,
			updateSelectedAveragePaymentSimulation,
		],
	);

	const calculations = useMemo(() => {
		if (!selectedItem || !selectedItem.salePriceFormation || !values.averagePaymentTerm) return {};

		const newFieldValues: Record<string, any> = {};
		const matchingTerm = options.find((term) => term.label === values.averagePaymentTerm);
		const selectedTerm = options.find((term) => term.selectedAveragePaymentSimulation);

		if (matchingTerm) {
			newFieldValues[`saleOrderSimulationItems[${selectedItemIndex}].salePriceFormation.unitValueAverageTerm`] = matchingTerm.unitValueAverageTerm;
		}

		if (selectedTerm) {
			newFieldValues[`saleOrderSimulationItems[${selectedItemIndex}].salePriceFormation.unitValueAverageTermSimulation`] = selectedTerm.unitValueAverageTerm;
			newFieldValues[`saleOrderSimulationItems[${selectedItemIndex}].salePriceFormation.averageTermSimulation`] = selectedTerm.interestRate;
			newFieldValues[`saleOrderSimulationItems[${selectedItemIndex}].salePriceFormation.averageTermSimulationId`] = selectedTerm.averagePaymentTermId;
		}

		if (!selectedTerm) {
			const updatedTerms = salePriceFormation.averagePaymentTermSalePriceFormations
				?.map((term: any) => ({
					...term,
					selectedAveragePaymentSimulation: term.averagePaymentTerm?.description
					=== values.averagePaymentTerm,
				}));

			newFieldValues[`saleOrderSimulationItems[${selectedItemIndex}].salePriceFormation.averagePaymentTermSalePriceFormations`] = updatedTerms;
		}

		return newFieldValues;
	}, [
		selectedItem,
		values.averagePaymentTerm,
		salePriceFormation.averagePaymentTermSalePriceFormations,
		selectedItemIndex,
		options,
	]);

	useFormikValuesUpdater(
		calculations,
		[calculations],
	);

	return (
		<Box sx={{ display: 'flex', position: 'relative' }}>
			<TableContainer component={Paper} sx={{ flexGrow: 1 }}>
				<Table size="small" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell sx={{ width: '20%' }}>
								<strong>Descrição</strong>
							</TableCell>
							<TableCell align="right" sx={{ width: '20%' }}>
								<strong>Taxa de Juros (%)</strong>
							</TableCell>
							<TableCell align="right" sx={{ width: '20%' }}>
								<strong>R$ Unitário (FPV.pm.V)</strong>
							</TableCell>
							{showExtraColumns && (
								<>
									<TableCell align="right" sx={{ width: '20%', backgroundColor: '#f0f0f0' }}>
										<strong>Descrição (SPPV.pm.D)</strong>
									</TableCell>
									<TableCell align="right" sx={{ width: '20%', backgroundColor: '#f0f0f0' }}>
										<strong>R$ Unitário (SPPV.pm.V)</strong>
									</TableCell>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor: showExtraColumns ? 'primary.dark' : 'primary.main',
									padding: 0,
									textAlign: 'center',
								}}
							>
								<Tooltip title={showExtraColumns ? 'Ocultar colunas de simulação' : 'Visualizar colunas de simulação'}>
									<IconButton
										sx={{ color: 'white' }}
										onClick={() => setShowExtraColumns(!showExtraColumns)}
									>
										{showExtraColumns ? <VisibilityOffIcon /> : <VisibilityIcon />}
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{options.map((option: any, index) => {
							const isSelected = option.label === values.averagePaymentTerm;
							const isSimulationSelected = option.selectedAveragePaymentSimulation;

							const defaultCellStyle = {
								fontWeight: isSelected ? 'bold' : 'normal',
							};

							const simulationCellStyle = {
								fontWeight: isSimulationSelected ? 'bold' : 'normal',
								backgroundColor: isSimulationSelected ? '#e0e0e0' : '#f0f0f0',
								cursor: 'pointer',
							};

							return (
								<TableRow key={option.id}>
									<TableCell sx={defaultCellStyle}>{option.label}</TableCell>
									<TableCell align="right" sx={defaultCellStyle}>{option.interestRate}</TableCell>
									<TableCell align="right" sx={defaultCellStyle}>
										{currencyBRLMask(option.unitValueAverageTerm, { decimalPlaces: 3 })}
									</TableCell>
									{showExtraColumns && (
										<>
											<TableCell
												align="right"
												sx={{
													...simulationCellStyle,
													backgroundColor: '#f0f0f0',
												}}
												onClick={() => !isReadOnly && handleRowClick(option, index)}
											>
												{option.label}
											</TableCell>
											<TableCell
												align="right"
												sx={{
													...simulationCellStyle,
													backgroundColor: '#f0f0f0',
												}}
												onClick={() => !isReadOnly && handleRowClick(option, index)}
											>
												{currencyBRLMask(option.unitValueAverageTerm, { decimalPlaces: 3 })}
											</TableCell>
										</>
									)}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			{showExtraColumns && (
				<Box
					sx={{
						width: '40px',
						backgroundColor: showExtraColumns ? 'primary.dark' : 'primary.main',
						position: 'absolute',
						right: 0,
						top: '40px',
						bottom: 0,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: 'calc(100% - 40px)',
					}}
				>
					<Typography
						sx={{
							writingMode: 'vertical-rl',
							transform: 'rotate(180deg)',
							color: 'white',
							fontWeight: 'bold',
							fontSize: '0.75rem',
							letterSpacing: '1px',
							textAlign: 'center',
							lineHeight: 'normal',
						}}
					>
						Simulação
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export default AveragePaymentTermSimulationTable;
