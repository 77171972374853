import React, { useCallback, useState } from 'react';
import {
	DataGrid, GridColDef, GridRenderCellParams,
	GridSortModel,
	GridValueFormatterParams,
} from '@mui/x-data-grid';
import { Description, Inventory2 } from '@mui/icons-material';
import {
	IconButton,
	Stack,
	Tooltip,
	Popover,
	MenuList,
	MenuItem,
	Paper,
	Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IInventory, statusTask } from '../../containers/Inventory/InventoryAssets';
import { formatUTCDateToLocal } from '../../helpers/DateUtils';

interface InventoryTableProps {
	inventories: IInventory[];
	loading: boolean;
	inventoryPage: number;
	inventoryCount: number;
	inventoryTake: number;
	sortModel: GridSortModel;
	onChangePage(productsTake: number): void;
	onChangePageSize(pageSize: number): void;
	onSortModelChange(sortModel: GridSortModel): void;
}

const InventoryTable = ({
	inventories,
	loading,
	inventoryPage,
	inventoryTake,
	sortModel,
	inventoryCount,
	onChangePage,
	onChangePageSize,
	onSortModelChange,
}: InventoryTableProps): JSX.Element => {
	const navigate = useNavigate();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [activeId, setActiveId] = useState('');

	const handleDetailsInventory = useCallback((id: string) => {
		navigate(id);
	}, [navigate]);

	const handleReportsClick = (
		event: any,
		id: string,
	):void => {
		setActiveId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleReportsClose = ():void => {
		setAnchorEl(null);
		setActiveId('');
	};

	const columns: GridColDef<(typeof inventories)[number]>[] = [
		{
			field: 'status',
			headerName: 'Status',
			editable: true,
			flex: 1,
			renderCell: (
				params: GridRenderCellParams,
			) => statusTask[params.value] || 'Status Desconhecido',
		},
		{
			field: 'document',
			headerName: 'Documento',
			editable: true,
			flex: 1,
		},
		{
			field: 'branch',
			headerName: 'Empresa / Filial',
			editable: true,
			flex: 1,
			renderCell: (
				params: GridRenderCellParams,
			) => (`${params.row.branch.company.name} / ${params.row.branch.name}`),
		},
		{
			field: 'date',
			headerName: 'Data de Criação',
			editable: true,
			flex: 1,
			valueFormatter: (
				params: GridValueFormatterParams<string>,
			) => formatUTCDateToLocal(params.value, 'dd/MM/yyyy'),
		},
		{
			field: 'id',
			headerName: 'Ações',
			description: 'This column has a value getter and is not sortable.',
			renderCell: (params) => (
				<Stack direction="row">
					<Tooltip title="Tarefas do Inventário">
						<IconButton
							aria-label="Tarefas do Inventário"
							onClick={() => handleDetailsInventory(params.id as string)}
						>
							<Inventory2 color="primary" />
						</IconButton>
					</Tooltip>
					<Tooltip title="Relatórios">
						<IconButton
							aria-label="Relatórios"
							onClick={(event) => handleReportsClick(event, params.id as string)}
						>
							<Description color="primary" />
						</IconButton>
					</Tooltip>

					<Popover
						open={Boolean(anchorEl)}
						anchorEl={anchorEl}
						onClose={handleReportsClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						elevation={1}
					>
						<MenuList>
							<MenuItem onClick={() => navigate(`summary/${activeId}`)}>
								Resumo do inventário
							</MenuItem>
							<MenuItem onClick={() => navigate(`report-by-operator/${activeId}`)}>
								Resumo do inventário por operador
							</MenuItem>
							<MenuItem onClick={() => navigate(`difference/${activeId}`)}>
								Diferenças do inventário
							</MenuItem>
						</MenuList>
					</Popover>
				</Stack>
			),
		},
	];

	return (
		<Box component={Paper} p={2}>
			<DataGrid
				autoHeight
				rows={inventories || []}
				columns={columns}
				loading={loading}
				page={inventoryPage}
				pageSize={inventoryTake}
				pagination
				paginationMode="server"
				sortingMode="server"
				rowCount={inventoryCount}
				rowsPerPageOptions={[10, 25, 50, 100]}
				onPageChange={onChangePage}
				onPageSizeChange={onChangePageSize}
				onSortModelChange={onSortModelChange}
				sortModel={sortModel}
			/>
		</Box>
	);
};

export default InventoryTable;
