/* eslint-disable import/no-duplicates */
import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import TruncateTooltipCell from '../components/Common/Datagrid/TruncateTooltipCell';
import { ProfitabilityAnalysisStatus } from '../enums/ProfitabilityAnalysisStatus';
import { ProfitabilityAnalysisStatusTypeLabels } from './profitabilityAnalysisStatusTypeLabels';
import { DeliveryType } from '../enums/DeliveryType';
import { DeliveryTypeLabels } from './deliveryTypeLabels';

export const profitabilityAnalysisColumns: GridColDef[] = [
	{ field: 'nrOrder', headerName: 'Nr Pedido', flex: 1 },
	{
		field: 'revision', headerName: 'Revisão', flex: 1,
	},
	{
		field: 'emissionDate',
		headerName: 'Data de Emissão',
		flex: 1,
		valueFormatter: ({ value }) => format(new Date(value), 'dd/MM/yyyy', { locale: ptBR }),
	},
	{
		field: 'salesman',
		headerName: 'Vendedor',
		flex: 1,
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.value} />
		),
	},
	{
		field: 'client',
		headerName: 'Cliente',
		flex: 1,
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.value} />
		),
	},
	{ field: 'paymentTerm', headerName: 'Condição de Pagto', flex: 1 },
	{ field: 'averagePaymentTerm', headerName: 'Prazo Médio', flex: 1 },
	{
		field: 'deliveryType',
		headerName: 'Frete',
		flex: 1,
		sortable: true,
		renderCell: (params: GridRenderCellParams<DeliveryType>) => (
			params.value != null ? DeliveryTypeLabels[params.value] : 'N/A'
		),
	},
	{ field: 'priceList', headerName: 'Tabela', flex: 1 },
	{ field: 'saleForceOrder', headerName: 'Pedido Força Venda', flex: 1 },
	{
		field: 'status',
		headerName: 'Status',
		flex: 1,
		sortable: true,
		renderCell: (params: GridRenderCellParams<ProfitabilityAnalysisStatus>) => (
			params.value != null ? ProfitabilityAnalysisStatusTypeLabels[params.value] : 'N/A'
		),
	},
];
