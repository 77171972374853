import { aliasesSalePriceFormationSimulation } from '../constants/aliases';
import { ISaleOrderSimulationItem, ISalePriceFormationAnalysis } from '../containers/ProfitabilityAnalysis/ProfitabilityAnalysisAssets';

export const calculateUnitValueIndirectCost = (
	totalDirectCost: number,
	indexValue: number,
	interestRate: number,
	totalInterestRate: number,
	commissionRate: number,
): number => (((totalDirectCost * indexValue) - totalDirectCost) * (interestRate / 100))
	/ ((totalInterestRate / 100) + (commissionRate / 100));

export const calculateUnitValueRangePriceCommission = (
	totalDirectCost: number,
	indexValue: number,
	commissionRate: number,
	totalIndirectCost: number,
): number => ((totalDirectCost * indexValue - totalDirectCost) * commissionRate)
/ (totalIndirectCost + commissionRate);

export const calculateSPPVIndexRangePriceCommission = (
	commissionRate: number,
	totalInterestRateSimulation: number,
): number => (1 / (1 - (commissionRate + totalInterestRateSimulation) / 100));

export const calculateUnitValueAverageTerm = (
	salePriceFormation: any,
	term: any,
): number => {
	const totalUnitValueIndirectCost = salePriceFormation?.totalUnitValueIndirectCost || 0;
	const unitValueRangeCommission = salePriceFormation?.unitValueRangeCommission || 0;

	return (
		(salePriceFormation.totalDirectCost || 0)
		+ totalUnitValueIndirectCost
		+ unitValueRangeCommission
	) * (term.interestRate / 100);
};

export const replaceAliasesInFormula = (formula: string, salePriceFormation: any): string => Object
	.keys(aliasesSalePriceFormationSimulation).reduce((acc, key) => {
		const value = salePriceFormation[aliasesSalePriceFormationSimulation[key]] || 0;
		return acc.replace(new RegExp(key, 'g'), value.toString());
	}, formula);

export const getSelectedItem = (
	items: ISaleOrderSimulationItem[],
	defaultValue: ISaleOrderSimulationItem,
): ISaleOrderSimulationItem => items.find((item) => item.selected) || defaultValue;

export const getSelectedItemIndex = (
	items: ISaleOrderSimulationItem[],
): number => items.findIndex((item) => item.selected);

export const getSalePriceFormation = (
	selectedItem: ISaleOrderSimulationItem,
	defaultValue: ISalePriceFormationAnalysis,
): ISalePriceFormationAnalysis => selectedItem?.salePriceFormation
|| defaultValue;

export const calculateGrossMargin = (salePriceFormation: ISalePriceFormationAnalysis): number => {
	const salePrice = salePriceFormation?.salePrice || 0;
	const totalDirectCost = salePriceFormation?.totalDirectCost || 0;
	if (salePrice === 0) return 0;
	return ((salePrice - totalDirectCost) / salePrice) * 100;
};

export const calculateGrossProfit = (selectedItem: ISaleOrderSimulationItem): number => {
	const salePrice = selectedItem?.salePriceFormation?.salePrice || 0;
	const totalDirectCost = selectedItem?.salePriceFormation?.totalDirectCost || 0;
	return salePrice - totalDirectCost;
};

export const calculateGrossProfitSaleOrder = (selectedItem: ISaleOrderSimulationItem): number => {
	const price = selectedItem?.price || 0;
	const totalDirectCost = selectedItem?.salePriceFormation?.totalDirectCost || 0;
	return price - totalDirectCost;
};

export const calculateGrossProfitSimulation = (
	selectedItem: ISaleOrderSimulationItem,
): number => {
	const simulationPrice = selectedItem?.simulationPrice || 0;
	const totalDirectCost = selectedItem?.salePriceFormation?.totalDirectCost || 0;
	return simulationPrice - totalDirectCost;
};

export const getTotalUnitValueIndirectCost = (
	selectedItem: ISaleOrderSimulationItem,
): number => selectedItem?.salePriceFormation?.totalUnitValueIndirectCost || 0;

export const getTotalUnitValueIndirectCostSimulation = (
	selectedItem: ISaleOrderSimulationItem,
): number => selectedItem?.salePriceFormation?.totalUnitValueIndirectCostSimulation || 0;

export const getUnitValueProfit = (selectedItem: ISaleOrderSimulationItem): number => {
	const costs = selectedItem?.salePriceFormation?.indirectCostSalePriceFormations || [];
	const lucroCost = costs.find((cost) => cost.indirectCost.description.toLowerCase() === 'lucro');
	return lucroCost?.unitValueI || 0;
};

export const getUnitValueProfitSimulation = (selectedItem: ISaleOrderSimulationItem): number => {
	const costs = selectedItem?.salePriceFormation?.indirectCostSalePriceFormations || [];
	const lucroCost = costs.find((cost) => cost.indirectCost.description.toLowerCase() === 'lucro');
	return lucroCost?.sppvUnitValueI || 0;
};

export const calculateNetProfit = (
	grossProfit: number,
	totalUnitValueIndirectCost: number,
	selectedItem: ISaleOrderSimulationItem,
): number => {
	const unitValueRangeCommission = selectedItem?.salePriceFormation?.unitValueRangeCommission || 0;
	return grossProfit - (totalUnitValueIndirectCost + unitValueRangeCommission)
	- (selectedItem?.salePriceFormation?.unitValueAverageTerm || 0);
};

export const calculateNetProfitSaleOrder = (
	grossProfitSaleOrder: number,
	totalUnitValueIndirectCost: number,
	selectedItem: ISaleOrderSimulationItem,
): number => {
	const unitValueRangeCommission = selectedItem?.salePriceFormation?.unitValueRangeCommission || 0;
	return grossProfitSaleOrder - (totalUnitValueIndirectCost + unitValueRangeCommission)
	- (selectedItem?.salePriceFormation?.unitValueAverageTerm || 0);
};

export const calculateNetProfitSimulation = (
	grossProfitSimulation: number,
	totalUnitValueIndirectCostSimulation: number,
	selectedItem: ISaleOrderSimulationItem,
): number => {
	const unitValueRangeCommissionSimulation = selectedItem?.salePriceFormation
		?.unitValueRangeCommissionSimulation || 0;
	return grossProfitSimulation - (totalUnitValueIndirectCostSimulation
		+ unitValueRangeCommissionSimulation)
		- (selectedItem?.salePriceFormation?.unitValueAverageTermSimulation || 0);
};

export const calculateNetProfitMargin = (
	netProfit: number,
	salePriceFormation: ISalePriceFormationAnalysis,
): number => {
	const salePrice = salePriceFormation?.salePrice || 0;
	if (salePrice === 0) return 0;
	return (netProfit / salePrice) * 100;
};

export const calculateNetProfitMarginSaleOrder = (
	netProfitSaleOrder: number,
	selectedItem: ISaleOrderSimulationItem,
): number => {
	const price = selectedItem?.price || 0;
	if (price === 0) return 0;
	return (netProfitSaleOrder / price) * 100;
};

export const calculateNetProfitMarginSimulation = (
	netProfitSimulation: number,
	selectedItem: ISaleOrderSimulationItem,
): number => {
	const simulationPrice = selectedItem?.simulationPrice || 0;
	if (simulationPrice === 0) return 0;
	return (netProfitSimulation / simulationPrice) * 100;
};

export const getFinancialCost = (
	selectedItem: ISaleOrderSimulationItem,
): number => (selectedItem?.salePriceFormation?.unitValueAverageTerm || 0);

export const getFinancialCostSimulation = (
	selectedItem: ISaleOrderSimulationItem,
): number => (selectedItem?.salePriceFormation?.unitValueAverageTermSimulation || 0);
