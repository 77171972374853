import React, {
	useState,
	useCallback,
	useMemo,
	useEffect,
	useRef,
} from 'react';
import {
	Drawer,
	Box,
	Typography,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Fab,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DescriptionIcon from '@mui/icons-material/Description';
import SaveIcon from '@mui/icons-material/Save';
import { useFormikContext } from 'formik';
import SalePriceFormationSimulationTable from '../Forms/SalePriceFormationSimulationTable';
import AveragePaymentTermSimulationTable from '../Forms/AveragePaymentTermSimulationTable';
import IndirectCostSimulationTable from '../Forms/IndirectCostSimulationTable';
import RangePriceCommissionSimulationTable from '../Forms/RangePriceCommissionSimulationTable';
import { initialSaleOrderSimulationItem, ISaleOrderSimulation } from '../../../containers/ProfitabilityAnalysis/ProfitabilityAnalysisAssets';
import { IIndirectCostSalePriceFormation } from '../../../containers/SalePriceFormation/SalePriceFormationAssets';
import useConfirmationDialog from '../../../hooks/useConfirmationDialog';
import { getSelectedItem } from '../../../helpers/ProfitabilityAnalysisCalculations';

interface SimulationDrawerProps {
	loading: boolean;
	upsertIndirectCostToFormation(data: IIndirectCostSalePriceFormation): void;
	updateSelectedRangeSimulation(
		rangeCommissionId: string,
		salePriceFormationId: string,
		selectedRangeSimulation: boolean,
	): void;
	updateSelectedAveragePaymentSimulation(
		averagePaymentTermId: string,
		salePriceFormationId: string,
		selectedAveragePaymentSimulation: boolean,
	): void;
}

interface FabProps {
	disabled: boolean;
	onSubmit: () => void;
}

const FabComponent = React.memo(({ disabled, onSubmit }: FabProps): JSX.Element => {
	const [hover, setHover] = useState(false);

	return (
		<Fab
			variant={hover ? 'extended' : 'circular'}
			size="large"
			color="primary"
			disabled={disabled}
			onMouseOver={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			onClick={onSubmit}
			sx={{
				position: 'fixed',
				bottom: 16,
				right: 16,
				zIndex: 1500,
				transition: 'all 0.3s ease',
				backgroundColor: disabled ? 'grey.500' : 'primary.main',
				'&:hover': {
					backgroundColor: disabled ? 'grey.500' : 'primary.dark',
				},
			}}
		>
			<SaveIcon sx={hover ? { mr: 1 } : {}} />
			{hover && 'Salvar Simulação'}
		</Fab>
	);
});

const SimulationDrawer = ({
	loading,
	upsertIndirectCostToFormation,
	updateSelectedRangeSimulation,
	updateSelectedAveragePaymentSimulation,
}: SimulationDrawerProps): JSX.Element | null => {
	const formik = useFormikContext<ISaleOrderSimulation>();
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [formChanged, setFormChanged] = useState(false);
	const prevSimulationPriceRef = useRef<number | null>(null);
	const prevItemIdRef = useRef<string | null>(null);
	const { requestConfirm, confirmationDialog } = useConfirmationDialog();

	const selectedItem = useMemo(
		() => getSelectedItem(
			formik.values.saleOrderSimulationItems,
			initialSaleOrderSimulationItem,
		),
		[formik.values.saleOrderSimulationItems],
	);

	useEffect(() => {
		const currentSimulationPrice = selectedItem?.simulationPrice;
		const currentItemId = selectedItem?.id;

		if (
			prevSimulationPriceRef.current !== null
			&& currentSimulationPrice !== prevSimulationPriceRef.current
			&& prevItemIdRef.current === currentItemId
		) {
			setFormChanged(true);
		}

		prevSimulationPriceRef.current = currentSimulationPrice;
		prevItemIdRef.current = currentItemId;
	}, [selectedItem]);

	const handleUpsertIndirectCostToFormation = useCallback(
		(data: IIndirectCostSalePriceFormation) => {
			upsertIndirectCostToFormation(data);
			setFormChanged(true);
		},
		[upsertIndirectCostToFormation],
	);

	const handleUpdateSelectedRangeSimulation = useCallback(
		(rangeCommissionId: string, salePriceFormationId: string, selectedRangeSimulation: boolean) => {
			updateSelectedRangeSimulation(
				rangeCommissionId,
				salePriceFormationId,
				selectedRangeSimulation,
			);
			setFormChanged(true);
		},
		[updateSelectedRangeSimulation],
	);

	const handleUpdateSelectedAveragePaymentSimulation = useCallback(
		(
			averagePaymentTermId: string,
			salePriceFormationId: string,
			selectedAveragePaymentSimulation: boolean,
		) => {
			updateSelectedAveragePaymentSimulation(
				averagePaymentTermId,
				salePriceFormationId,
				selectedAveragePaymentSimulation,
			);
			setFormChanged(true);
		},
		[updateSelectedAveragePaymentSimulation],
	);

	const handleDrawerToggle = useCallback(() => {
		if (formChanged) {
			requestConfirm({
				title: 'Sair sem salvar?',
				description: 'Você fez alterações que não foram salvas. Deseja sair sem salvar?',
				callback: () => {
					setIsDrawerOpen((prevOpen) => !prevOpen);
					setFormChanged(false);
				},
			});
		} else {
			setIsDrawerOpen((prevOpen) => !prevOpen);
		}
	}, [formChanged, requestConfirm]);

	const handleSubmit = useCallback(() => {
		formik.handleSubmit();
		setIsDrawerOpen(false);
		setFormChanged(false);
	}, [formik]);

	const fabMemo = useMemo(() => (
		<FabComponent
			disabled={loading || !formChanged}
			onSubmit={handleSubmit}
		/>
	), [loading, formChanged, handleSubmit]);

	return (
		<>
			{confirmationDialog}
			<Box
				sx={{
					position: 'fixed',
					right: 0,
					top: 0,
					bottom: 0,
					width: '40px',
					backgroundColor: 'primary.main',
					color: '#fff',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					cursor: 'pointer',
					zIndex: 1100,
					'&:hover': {
						backgroundColor: 'primary.dark',
					},
				}}
				onClick={handleDrawerToggle}
			>
				<DescriptionIcon sx={{ marginBottom: 1 }} />
				<ChevronLeftIcon />
				<Typography variant="body2" sx={{ writingMode: 'vertical-rl', textOrientation: 'mixed' }}>
					Simulação
				</Typography>
			</Box>
			<Drawer
				variant="temporary"
				anchor="right"
				open={isDrawerOpen}
				onClose={handleDrawerToggle}
				sx={{
					'& .MuiDrawer-paper': {
						width: '1200px',
					},
				}}
			>
				<Box
					sx={{
						marginTop: '60px',
						width: '1200px',
						padding: 2,
						height: '100%',
						boxSizing: 'border-box',
					}}
					role="presentation"
				>
					<Accordion defaultExpanded>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography variant="h6">Formação de Preço</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<SalePriceFormationSimulationTable />
						</AccordionDetails>
					</Accordion>
					<Accordion defaultExpanded>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography variant="h6">Prazo Médio de Pagamento</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<AveragePaymentTermSimulationTable
								updateSelectedAveragePaymentSimulation={
									handleUpdateSelectedAveragePaymentSimulation
								}
							/>
						</AccordionDetails>
					</Accordion>
					<Accordion defaultExpanded>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography variant="h6">Custos Indiretos</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<IndirectCostSimulationTable
								upsertIndirectCostToFormation={handleUpsertIndirectCostToFormation}
							/>
						</AccordionDetails>
					</Accordion>
					<Accordion defaultExpanded>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography variant="h6">Faixa Comissão</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<RangePriceCommissionSimulationTable
								updateSelectedRangeSimulation={handleUpdateSelectedRangeSimulation}
							/>
						</AccordionDetails>
					</Accordion>
					{fabMemo}
				</Box>
			</Drawer>
		</>
	);
};

export default SimulationDrawer;
