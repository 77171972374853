/* eslint-disable @typescript-eslint/no-explicit-any */
import { formatNumberForLocale } from '../../helpers/NumberUtils';
import { validationMessage } from '../../helpers/yupMessages';
import Yup from '../../helpers/yupMethods';
import { LocationProductQueryParams } from '../../interfaces/LocationProduct';
import { IMeasure, IProduct } from '../Product/ProductAssets';

export enum OperationType {
  EDITING = 'EDITING',
  NEW = 'NEW',
}

export interface EditProductFormProps {
  valuedQuantity: string;
  currentQuantity: string;
}

export interface IProductMeasures extends IProduct {
  measures: IMeasure[];
}

export interface ILocationProduct {
  locationId: string;
  productId: string;
  valuedQuantity: string;
  currentQuantity: string;
}

export interface ILocationProductExtended extends ILocationProduct {
  product: IProductMeasures;
}

export const initialValues = {
	valuedQuantity: '',
	productId: '',
	locationId: '',
	product: null as unknown as IProduct,
};

export interface ILocation {
  id: string;
  code: number;
  barCode: string;
  barCodeFormatted: string;
  barCodeNumber?: string;
  barCodeInitial?: string;
  name: string;
  companyId: string;
  branchId: string;
  locationTypeId: string | null;
  occupation: number;
  active: boolean;
  locationId: string;
  locationCode: string;
  locationName: string;
  mainLocation?: ILocation;
  products?: ILocationProductExtended[];
  locations?: ILocation[];
  locationType?: {
    level: number;
    prefix: string;
  };
}

export interface ILocationWithPagination {
  locations: Array<ILocation>
  count: number;
}

export interface ILocationMain {
  id: string;
  name: string;
  locations: ILocation;
}

export interface ItemTree {
  id: string;
  name: string;
  code: number;
  locationId: string | null;
  locationType: ILocationType;
  children?: Location[];
}

export interface ILocationTree {
  id: string;
  code: number;
  name: string;
  locationId: string;
  locationType: ILocationType;
}

export interface ILocationType {
  prefix: string;
  level: number;
  id?: string;
  name: string;
  canEditPrefix: boolean;
}

export interface ILocationTypes {
  data: ILocationType[];
  count: number;
}

export interface ILocationProductTableRow {
  id: string;
  description: string;
  measure1: string;
  valuedQuantity: string;
  currentQuantity: string;
  measure2: string;
  valuedQuantity2: string;
  currentQuantity2: string;
  locationProduct: ILocationProductExtended;
}

export interface ILocationRequest {
  locationId: string;
  locationTypeId: string;
  name: string;
  barCode: string;
  barCodeInitial?: string;
  barCodeNumber?: string;
  companyId?: string;
  branchId?: string;
}

export interface ILocationInfo
  extends Pick<ILocation, 'barCode' | 'name' | 'id' | 'locationTypeId' | 'locationId'> {
  barCodeInitial: string;
  barCodeNumber: string;
  companyId: string;
  branchId: string;
}

export interface ProductInLocation {
  id: string;
  code: string;
  barCode: string;
  description: string;
}

export interface ILocationProductWithoutDetails {
  id: string;
  name: string;
  barCode: string;
  products: ProductInLocation[]
}

export const initialValuesDefault: ILocationInfo = {
	id: '',
	name: '',
	barCode: '',
	locationTypeId: null,
	barCodeInitial: '',
	barCodeNumber: '',
	locationId: '',
	companyId: '',
	branchId: '',
};

export const initialValueLocationFilter: Pick<LocationProductQueryParams, 'locationValue' | 'productIds' | 'currentQuantity' | 'available'> = {
	locationValue: '',
	productIds: [],
	currentQuantity: '' as any,
	available: '' as any,
};

export const validationSchemaProduct = Yup.object().shape({
	valuedQuantity: Yup.number()
		.required(validationMessage.required)
		.typeError(validationMessage.number.typeError),
	description: Yup.string().required(validationMessage.required),
});

export const validationSchemaEditProduct = Yup.object().shape({
	currentQuantity: Yup.number().required().min(0, validationMessage.number.min(0)),
	valuedQuantity: Yup.number()
		.required(validationMessage.required)
		.min(0, validationMessage.number.min(0))
		.test(
			'capacity-location-test',
			'A capacidade total não deve ser menor que a capacidade já utilizada',
			(value, context) => {
				const { currentQuantity } = context.parent;

				const valuedQuantity = Number(value) || 0;
				const currentQuantityNumber = Number(currentQuantity) || 0;

				if (valuedQuantity < currentQuantityNumber) {
					return context.createError({
						message: `A capacidade informada de ${formatNumberForLocale(
							valuedQuantity,
							2,
						)}, não deve ser menor que a capacidade já utilizada de ${formatNumberForLocale(
							currentQuantityNumber,
							2,
						)}`,
					});
				}

				return true;
			},
		),
});

export const validationSchemaLocation = Yup.object().shape({
	name: Yup.string().required(validationMessage.required),
	barCodeInitial: Yup.string().required(validationMessage.required),
	barCodeNumber: Yup.string()
		.max(2, validationMessage.string.max(2))
		.required(validationMessage.required),
	locationTypeId: Yup.string().required(validationMessage.required).nullable(),
	branchId: Yup.string().required(validationMessage.required),
	companyId: Yup.string().required(validationMessage.required),
});
