import React, { Dispatch, Reducer, useReducer } from 'react';
import {
	SnackbarMessage,
	OptionsObject,
	SnackbarKey,
	useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import JsBarcode from 'jsbarcode';
import ProductPresentational from '../../components/Product/Product';
import {
	deleteProduct,
	getProductsWithoutDetails,
} from '../../services/product';
import { IProductWithoutDetails } from './ProductAssets';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import PrintLabelTemplate from '../../constants/printLabelTemplate';

enum ActionType {
    LOADING,
    PRODUCT,
}

interface IState {
    count: number;
    loading: boolean;
    products: IProductWithoutDetails[];
    productsPages: number;
    productsPage: number;
    productsTake: number;
}

type TAction =
    | { type: ActionType.LOADING; payload: { loading: boolean } }
    | {
        type: ActionType.PRODUCT; payload: {
            products: IProductWithoutDetails[],
            count: number,
            productsPages: number,
            productsPage: number,
            productsTake: number,
        }
    };

interface IProductActions {
    setLoading(loading: boolean): void;
    getProducts(queryParams: ProductQueryParams): void;
    handleDeleteProduct(id: string): void;
	printLabel(barCode: string): void;
}

const initialState: IState = {
	count: 0,
	loading: false,
	products: [],
	productsPages: 0,
	productsPage: 0,
	productsTake: 10,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.PRODUCT:
			return {
				...state,
				products: action.payload.products,
				count: action.payload.count,
				productsPages: action.payload.productsPages,
				productsPage: action.payload.productsPage,
				productsTake: action.payload.productsTake,
			};
		default:
			throw new Error();
	}
};

const ProductActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): IProductActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getProducts(queryParams: ProductQueryParams) {
			actions.setLoading(true);
			const take = queryParams.take ?? 10;
			const params = { ...queryParams, skip: queryParams.skip * take };
			getProductsWithoutDetails(params).then((response) => {
				dispatch({
					type: ActionType.PRODUCT,
					payload: {
						products: response.data.data,
						count: response.data.count,
						productsPages: response.data.count,
						productsPage: queryParams.skip,
						productsTake: take,
					},
				});
				actions.setLoading(false);
			});
		},
		handleDeleteProduct(id: string) {
			actions.setLoading(true);
			deleteProduct(id).then((response) => {
				enqueueSnackbar(response.data.message, { variant: 'success' });
			})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
					actions.getProducts({ skip: 0 });
				});
		},
		printLabel(barCode: string) {
			const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

			JsBarcode(svgElement, barCode, {
				format: 'CODE128',
				displayValue: true,
				width: 2,
				height: 80,
				fontSize: 40,
			});

			const svgContent = svgElement.outerHTML;

			const templateString = PrintLabelTemplate({ svgContent });

			const printWindow = window.open('', '_blank');
			if (printWindow) {
				printWindow.document.open();
				printWindow.document.write(templateString);
				printWindow.document.close();
				printWindow.onload = () => printWindow.print();
			} else {
				enqueueSnackbar('Não foi possível abrir a janela de impressão.', {
					variant: 'error',
				});
			}
		},
	};

	return actions;
};

const Product = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const actions = ProductActions(dispatch, enqueueSnackbar);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <ProductPresentational {...state} {...actions} />;
};

export default Product;
