import React, {
	useCallback, useMemo, useState,
} from 'react';
import { styled, Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HelpIcon from '@mui/icons-material/Help';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import { Link } from 'react-router-dom';
import { Stack, Tooltip, Typography } from '@mui/material';
import { logout } from '../../services/auth';
import DrawerMenu from './DrawerMenu';
import { drawerWidth } from '../../constants/menu';
import ChangePasswordDialog from '../ChangePassword/ChangePasswordDialog';
import { getUserData } from '../../helpers/getUserData';
import usePermission from '../../hooks/usePermission';

const navbar: SxProps<Theme> = {
	boxShadow: 0,
	backgroundColor: '#fff',
	borderBottom: '1px solid #e1e1e1',
};

const AppBar: any = styled(MuiAppBar, {
	shouldForwardProp: (prop: any) => prop !== 'open',
})(({ theme, open }: any) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const toolbar: SxProps<Theme> = {
	display: 'flex',
	gap: '2',
};

const imageBox: SxProps<Theme> = {
	display: 'flex',
};

const image: (menuOpen: boolean) => SxProps<Theme> = (menuOpen) => {
	let style: SxProps<Theme> = {
		height: '100%',
		width: '10rem',
	};

	if (!menuOpen) {
		style = { ...style, marginLeft: '1rem' };
	}

	return style;
};

const Navbar = (): JSX.Element => {
	const [menuOpen, setMenuOpen] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);
	const companyPermission = usePermission('COMPANY');

	const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (): void => {
		setAnchorEl(null);
	};

	const handleLogout = useCallback(() => {
		logout();
	}, []);

	const handleOpenChangePasswordDialog = useCallback(() => {
		setOpenChangePassword(true);
	}, []);

	const handleCloseChangePasswordDialog = useCallback(() => {
		setOpenChangePassword(false);
	}, []);

	const toggleDrawer = (): void => {
		setMenuOpen(!menuOpen);
	};

	const menuItems = useMemo(() => ([
		{
			label: 'Alterar senha',
			onClick: handleOpenChangePasswordDialog,
		},
		{
			label: 'Logout',
			onClick: handleLogout,
		},
	]), [handleLogout, handleOpenChangePasswordDialog]);

	return (
		<>
			<AppBar position="fixed" color="primary" sx={navbar} open={menuOpen}>
				<Toolbar sx={toolbar}>
					<IconButton
						size="large"
						edge="start"
						aria-label="menu"
						onClick={toggleDrawer}
						sx={{
							...(menuOpen && { display: 'none' }),
						}}
					>
						<MenuIcon />
					</IconButton>
					<Box component={Link} to="/" sx={imageBox}>
						<Box component="img" alt="Logo" src="/api/configuration/logo" sx={image(menuOpen)} />
					</Box>
					{companyPermission?.isAdmin && (
						<Stack direction="row" spacing={2} ml={2} alignItems="center">
							<Link to="/business-intelligence">
								<Button variant="text" startIcon={<LineAxisIcon />}>
									Business Intelligence
								</Button>
							</Link>
						</Stack>
					)}
					<Stack direction="row" spacing={1} alignItems="center" sx={{ ml: 'auto' }}>
						<Tooltip title="Manual do Portal">
							<IconButton
								size="large"
								aria-label="help"
								onClick={() => window.open('https://maisi9-my.sharepoint.com/:b:/g/personal/diego_fortunato_maisi9_com_br/EYFJuSdP0d9JrxYyD1lXjC4BXnORb_vOIOnmWDAI4rSkYQ?e=mSuFdt', '_blank')}
								sx={{ color: '#444' }}
							>
								<HelpIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title={getUserData()?.name || 'Conta'}>
							<IconButton
								size="large"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleMenu}
								sx={{ color: '#444' }}
							>
								<AccountCircle />
							</IconButton>
						</Tooltip>
						<Menu
							sx={{ mt: '45px' }}
							id="menu-appbar"
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							{menuItems.map((menuItem) => (
								<MenuItem
									key={menuItem.label}
									onClick={menuItem.onClick}
								>
									{menuItem.label}
								</MenuItem>
							))}
						</Menu>
					</Stack>
				</Toolbar>
			</AppBar>
			<DrawerMenu open={menuOpen} toggleDrawer={toggleDrawer} />
			<ChangePasswordDialog
				open={openChangePassword}
				handleClose={handleCloseChangePasswordDialog}
			/>
		</>
	);
};

export default Navbar;
