export const aliasesSalePriceFormation: { [key: string]: string } = {
	A: 'totalDirectCost',
	B: 'baseCommission',
	C: 'averageTerm',
	D: 'totalIndirectCost',
	E: 'indexValue',
	F: 'discountIndex',
};

export const aliasesSalePriceFormationSimulation: { [key: string]: string } = {
	'FPV.pv': 'salePrice',
	'FPV.cd': 'totalDirectCost',
	'FPV.mb': 'grossProfitMargin',
	'FPV.lb': 'grossProfit',
	'FPV.ll': 'netProfit',
	'FPV.mll': 'netProfitMargin',
	'FPV.ci': 'totalUnitValueIndirectCost',
	'FPV.co': 'unitValueRangeCommission',
	'FPV.cf': 'unitValueAverageTerm',
};

export const formulas = {
	FPV_mb: '((FPV.pv - FPV.cd) / FPV.pv) * 100', // Margem Bruta
	FPV_lb: 'FPV.pv - FPV.cd', // Lucro Bruto
	FPV_ll: '(FPV.lb-(FPV.ci.V.1 + FPV.ci.V.2 + FPV.ci.V.3 + FPV.ci.V.4 + FPV.fc.V))', // Lucro Líquido
	FPV_mll: 'FPV.ll / FPV.pv * 100', // Margem Lucro Líquido
};
