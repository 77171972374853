import React, { useEffect } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import {
	Form,
	FormikContext,
	useFormik,
} from 'formik';
import Autocomplete from '../../Common/Form/Autocomplete';
import { ICompany, ICompanyWithoutDetails } from '../../../containers/Company/CompanyAssets';
import { IBranch } from '../../../containers/Branch/BranchAssets';
import { BranchParams, InventoryType, ParamsStartInventory } from '../../../containers/Inventory/InventoryAssets';

interface StartInventoryModalProps {
    open: boolean;
    onClose: () => void;
    getBranches: (params: BranchParams) => void;
	branches: IBranch[];
	loading: boolean;
    handleConfirmStartInventory: (params: ParamsStartInventory) => void;
	companies: ICompanyWithoutDetails[];
}

const initialValues = {
	companyId: null,
	branchId: null,
	type: null,
};

const types = [
	{ label: 'Inventário Geral', id: InventoryType.GERAL },
	{ label: 'Parcial/Cíclico', id: InventoryType.PARTIAL },
];

const StartInventoryModal = ({
	open,
	onClose,
	branches,
	getBranches,
	loading,
	companies,
	handleConfirmStartInventory,
}: StartInventoryModalProps): JSX.Element => {
	const formik = useFormik({
		initialValues,
		onSubmit: () => {
			handleConfirmStartInventory({
				companyId: formik.values.companyId,
				branchId: formik.values.branchId,
				type: formik.values.type,
			});
		},
	});

	useEffect(() => {
		if (formik.values.companyId) {
			getBranches({
				companyId: formik.values.companyId,
			});
		}
	}, [formik.values.companyId, getBranches]);

	return (
		<FormikContext.Provider value={formik}>
			<Dialog open={open} fullWidth>
				<Form>
					<DialogTitle>Iniciar Inventário</DialogTitle>
					<DialogContent>
						<Autocomplete
							label="Empresa"
							options={companies}
							name="companyId"
							labelKey="name"
							valueKey="id"
							valueLabel="code"
							sx={{ my: 2 }}
							closeOnSelect
							required
						/>
						<Autocomplete
							label="Filial"
							options={branches}
							name="branchId"
							labelKey="name"
							valueKey="id"
							valueLabel="code"
							sx={{ my: 2 }}
							disabled={!formik.values.companyId}
							loading={loading}
							closeOnSelect
							required
						/>
						<Autocomplete
							label="Tipo de Inventário"
							options={types}
							name="type"
							labelKey="label"
							valueKey="id"
							valueLabel="id"
							disabled={!formik.values.branchId || !formik.values.companyId}
							closeOnSelect
							required
						/>
					</DialogContent>
					<DialogActions sx={{ gap: 1 }}>
						<Button onClick={onClose}>Cancelar</Button>
						<Button
							type="submit"
							variant="contained"
							disabled={
								(formik.values.type === null)
								|| (formik.values.type === null)
								|| (formik.values.type === null)
								|| loading
							}
						>
							Confirmar
						</Button>
					</DialogActions>
				</Form>
			</Dialog>
		</FormikContext.Provider>
	);
};

export default StartInventoryModal;
