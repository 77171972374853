import React, { useCallback } from 'react';
import { Form, Formik } from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Input from '../Common/Form/Input';
import { SaleOrderSimulationQueryParams } from '../../interfaces/SaleOrderSimulationQueryParams';
import { DatePicker } from '../Common/Form/DatePicker';
import { initialValuesFilter } from '../../containers/ProfitabilityAnalysis/ProfitabilityAnalysisAssets';

interface IFilterProps {
	sendFilter: (values: SaleOrderSimulationQueryParams) => void;
	initialValues: SaleOrderSimulationQueryParams;
}

const ProfitabilityAnalysisFilter = ({
	sendFilter,
	initialValues,
}: IFilterProps): JSX.Element => {
	const onSubmit = useCallback((values: SaleOrderSimulationQueryParams) => {
		sendFilter(values);
	}, [sendFilter]);

	const onReset = useCallback((handleReset) => {
		sendFilter(initialValuesFilter);
		handleReset();
	}, [sendFilter]);

	return (
		<Formik initialValues={initialValues} onSubmit={onSubmit}>
			{({ handleReset }) => (
				<Form>
					<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>
						<Grid item xs={12} sm={6}>
							<DatePicker name="dateFrom" label="Data Inicial" />
						</Grid>
						<Grid item xs={12} sm={6}>
							<DatePicker name="dateTo" label="Data Final" />
						</Grid>
						<Grid item xs={12}>
							<Input.InputField
								id="nrOrder"
								name="nrOrder"
								label="Nr do Pedido"
								autoComplete="off"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
							<Button variant="outlined" onClick={() => onReset(handleReset)}>
								Restaurar
							</Button>
							<Button variant="contained" type="submit">
								Filtrar
							</Button>
						</Grid>
					</Grid>
				</Form>
			)}
		</Formik>
	);
};

export default ProfitabilityAnalysisFilter;
