import React, { useMemo, useState, useCallback } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Box,
	Tooltip,
	IconButton,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CalculateIcon from '@mui/icons-material/Calculate';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import {
	initialSaleOrderSimulationItem,
	initialSalePriceFormationAnalysis,
	ISaleOrderSimulation,
} from '../../../containers/ProfitabilityAnalysis/ProfitabilityAnalysisAssets';
import { currencyBRLMask } from '../../../helpers/intl';
import Input from '../../Common/Form/Input';
import {
	calculateGrossMargin,
	calculateGrossProfit,
	calculateGrossProfitSaleOrder,
	calculateGrossProfitSimulation,
	calculateNetProfit,
	calculateNetProfitMargin,
	calculateNetProfitMarginSimulation,
	calculateNetProfitSaleOrder,
	calculateNetProfitSimulation,
	getFinancialCost,
	getFinancialCostSimulation,
	getSalePriceFormation,
	getSelectedItem,
	getSelectedItemIndex,
	getTotalUnitValueIndirectCost,
	getTotalUnitValueIndirectCostSimulation,
} from '../../../helpers/ProfitabilityAnalysisCalculations';
import { useFormikValuesUpdater } from '../../../hooks/useFormikValuesUpdater';
import { formatPercentage } from '../../../helpers/Utils';
import { ProfitabilityAnalysisStatus } from '../../../enums/ProfitabilityAnalysisStatus';

const SalePriceFormationSimulationTable = (): JSX.Element => {
	const { values, setFieldValue } = useFormikContext<ISaleOrderSimulation>();
	const [showExtraColumns, setShowExtraColumns] = useState(true);
	const { enqueueSnackbar } = useSnackbar();

	const selectedItem = useMemo(
		() => getSelectedItem(
			values.saleOrderSimulationItems,
			initialSaleOrderSimulationItem,
		),
		[values.saleOrderSimulationItems],
	);

	const selectedItemIndex = useMemo(
		() => getSelectedItemIndex(values.saleOrderSimulationItems),
		[values.saleOrderSimulationItems],
	);

	const salePriceFormation = useMemo(
		() => getSalePriceFormation(selectedItem, initialSalePriceFormationAnalysis),
		[selectedItem],
	);

	const isReadOnly = useMemo(
		() => [
			ProfitabilityAnalysisStatus.APPROVED,
			ProfitabilityAnalysisStatus.REJECTED].includes(values.status),
		[values.status],
	);

	const grossMargin = calculateGrossMargin(salePriceFormation);
	const grossProfit = calculateGrossProfit(selectedItem);
	const grossProfitSaleOrder = calculateGrossProfitSaleOrder(selectedItem);
	const grossProfitSimulation = calculateGrossProfitSimulation(selectedItem);

	const totalUnitValueIndirectCost = getTotalUnitValueIndirectCost(selectedItem);
	const totalUnitValueIndirectCostSimulation = getTotalUnitValueIndirectCostSimulation(
		selectedItem,
	);

	const netProfit = calculateNetProfit(
		grossProfit,
		totalUnitValueIndirectCost,
		selectedItem,
	);

	const netProfitSaleOrder = calculateNetProfitSaleOrder(
		grossProfitSaleOrder,
		totalUnitValueIndirectCost,
		selectedItem,
	);

	const netProfitSimulation = calculateNetProfitSimulation(
		grossProfitSimulation,
		totalUnitValueIndirectCostSimulation,
		selectedItem,
	);

	const netProfitMargin = calculateNetProfitMargin(
		netProfit,
		salePriceFormation,
	);

	const netProfitMarginSimulation = calculateNetProfitMarginSimulation(
		netProfitSimulation,
		selectedItem,
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const financialCost = useMemo(() => getFinancialCost(selectedItem), [
		selectedItem.salePriceFormation?.unitValueAverageTerm]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const financialCostSimulation = useMemo(() => getFinancialCostSimulation(selectedItem), [
		selectedItem.salePriceFormation?.unitValueAverageTermSimulation]);

	useFormikValuesUpdater(
		{
			[`saleOrderSimulationItems[${selectedItemIndex}].grossMargin`]:
				grossMargin,
			[`saleOrderSimulationItems[${selectedItemIndex}].netProfitMargin`]:
				netProfitMargin,
			[`saleOrderSimulationItems[${selectedItemIndex}].grossProfitSimulation`]:
				grossProfitSimulation,
			[`saleOrderSimulationItems[${selectedItemIndex}].netProfitSimulation`]:
				netProfitSimulation,
			[`saleOrderSimulationItems[${selectedItemIndex}].netProfitMarginSimulation`]:
				netProfitMarginSimulation,
		},
		[
			grossMargin,
			netProfitMargin,
			grossProfitSimulation,
			netProfitSimulation,
			netProfitMarginSimulation,
		],
	);

	const handleCalculateSimulationPrice = useCallback(() => {
		if (!selectedItem || !salePriceFormation?.formula) {
			enqueueSnackbar('Fórmula não definida', { variant: 'error' });
			return;
		}

		try {
			const vistaTermId = salePriceFormation.averagePaymentTermSalePriceFormations
				?.find((term) => {
					const normalizedDescription = (term.averagePaymentTerm.description ?? '').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
					return ['a vista', 'à vista', 'avista', 'a vista', 'vista'].includes(normalizedDescription);
				})?.averagePaymentTermId;

			const vistaTerm = salePriceFormation.averagePaymentTermSalePriceFormations
				?.find((term) => term.averagePaymentTermId === vistaTermId);
			const vistaInterestRate = vistaTerm ? vistaTerm.interestRate : 0;

			const simulationAliases = {
				A: salePriceFormation.totalDirectCost,
				B: selectedItem?.salePriceFormation?.baseCommission,
				C: vistaInterestRate,
				D: salePriceFormation.totalIndirectCost,
				E: selectedItem?.salePriceFormation?.indexValueRangeCommissionSimulation,
				F: selectedItem?.salePriceFormation?.discountIndex,
			};

			const replacedFormulaVista = Object.keys(simulationAliases).reduce((acc, key) => {
				const value = simulationAliases[key as keyof typeof simulationAliases];
				return acc.replace(new RegExp(key, 'g'), value !== undefined ? value.toString() : '0');
			}, salePriceFormation.formula);

			// eslint-disable-next-line no-eval
			const resultVista = eval(replacedFormulaVista.replace(/\\/g, '/'));

			const selectedTermId = selectedItem?.salePriceFormation?.averageTermSimulationId;
			const selectedTerm = salePriceFormation.averagePaymentTermSalePriceFormations
				?.find((term) => term.averagePaymentTermId === selectedTermId);
			const selectedInterestRate = selectedTerm ? selectedTerm.interestRate : 0;

			let finalResult = resultVista;

			if (selectedTerm?.averagePaymentTermId !== vistaTermId) {
				finalResult = resultVista * (1 + selectedInterestRate / 100);
			}

			setFieldValue(`saleOrderSimulationItems[${selectedItemIndex}].simulationPrice`, finalResult);
			enqueueSnackbar('Preço de simulação calculado com sucesso', { variant: 'success' });
		} catch (error) {
			enqueueSnackbar('Erro ao calcular o preço de simulação', { variant: 'error' });
		}
	}, [
		selectedItem,
		salePriceFormation,
		selectedItemIndex,
		setFieldValue,
		enqueueSnackbar,
	]);

	const backgroundColor = useMemo(
		() => (showExtraColumns ? 'primary.dark' : 'primary.main'),
		[showExtraColumns],
	);

	return (
		<Box sx={{ display: 'flex', position: 'relative' }}>
			<TableContainer component={Paper} sx={{ flexGrow: 1 }}>
				<Table size="small" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell sx={{ width: '20%' }}>
								<strong>Descrição (FPV)</strong>
							</TableCell>
							<TableCell sx={{ width: '10%' }} align="right">
								<strong>Valores</strong>
							</TableCell>
							<TableCell sx={{ width: '10%' }} align="right">
								<strong>(%)</strong>
							</TableCell>
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{
											width: '20%',
											backgroundColor: '#f0f0f0',
										}}
									>
										<strong>Pedido Venda (PV)</strong>
									</TableCell>
									<TableCell
										align="right"
										sx={{
											width: '10%',
											backgroundColor: '#f0f0f0',
										}}
									>
										<strong>
											(%)
										</strong>
									</TableCell>
									<TableCell
										align="right"
										sx={{
											width: '20%',
											backgroundColor: '#f0f0f0',
										}}
									>
										<strong>
											Simulação Preço PV (SPPV)
										</strong>
									</TableCell>
									<TableCell
										align="right"
										sx={{
											width: '10%',
											backgroundColor: '#f0f0f0',
										}}
									>
										<strong>
											(%)
										</strong>
									</TableCell>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							>
								<Tooltip
									title={
										showExtraColumns
											? 'Ocultar colunas de simulação'
											: 'Visualizar colunas de simulação'
									}
								>
									<IconButton
										sx={{ color: 'white' }}
										onClick={() => setShowExtraColumns(
											!showExtraColumns,
										)}
									>
										{showExtraColumns ? (
											<VisibilityOffIcon />
										) : (
											<VisibilityIcon />
										)}
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>Preço Venda</TableCell>
							<TableCell align="right">
								<Tooltip title={salePriceFormation?.formula}>
									<span>
										{currencyBRLMask(
											salePriceFormation?.salePrice || 0,
											{ decimalPlaces: 3 },
										)}
									</span>
								</Tooltip>
							</TableCell>
							<TableCell />
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(selectedItem.price, {
											decimalPlaces: 3,
										})}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										<Input.FinancialInputField
											name={`saleOrderSimulationItems[${selectedItemIndex}].simulationPrice`}
											size="small"
											decimalScale={3}
											InputProps={{
												readOnly: isReadOnly,
												sx: {
													backgroundColor: '#e0e0e0',
													'& .MuiOutlinedInput-notchedOutline': {
														border: 'none',
													},
													'& input': {
														textAlign: 'right',
														padding: '2px 8px',
													},
												},
											}}
										/>
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							>
								<Tooltip title="Calcular Preço da Simulação">
									<IconButton
										onClick={handleCalculateSimulationPrice}
										disabled={!salePriceFormation?.formula || isReadOnly}
									>
										<CalculateIcon
											sx={{ color: 'white' }}
										/>
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Custo Direto (CPV)</TableCell>
							<TableCell align="right">
								{currencyBRLMask(
									salePriceFormation?.totalDirectCost ?? 0,
									{ decimalPlaces: 3 },
								)}
							</TableCell>
							<TableCell />
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(
											salePriceFormation?.totalDirectCost ?? 0,
											{ decimalPlaces: 3 },
										)}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							/>
						</TableRow>
						<TableRow>
							<TableCell>Lucro Bruto</TableCell>
							<TableCell align="right">
								{currencyBRLMask(
									grossProfit,
									{ decimalPlaces: 3 },
								)}
							</TableCell>
							<TableCell align="right">
								{formatPercentage((grossProfit / (salePriceFormation?.salePrice || 0)) * 100)}
							</TableCell>
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(
											grossProfitSaleOrder || 0,
											{ decimalPlaces: 3 },
										)}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{`${((grossProfitSaleOrder / selectedItem.price) * 100).toFixed(2)}%`}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(
											grossProfitSimulation || 0,
											{ decimalPlaces: 3 },
										)}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{`${((grossProfitSimulation / (selectedItem.simulationPrice || 0)) * 100).toFixed(2)}%`}
									</TableCell>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							/>
						</TableRow>
						<TableRow>
							<TableCell>Lucro Líquido</TableCell>
							<TableCell align="right">
								{currencyBRLMask(
									netProfit,
									{ decimalPlaces: 3 },
								)}
							</TableCell>
							<TableCell align="right">
								{formatPercentage((netProfit / (salePriceFormation?.salePrice || 0)) * 100)}
							</TableCell>
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(
											netProfitSaleOrder || 0,
											{ decimalPlaces: 3 },
										)}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{formatPercentage((netProfitSaleOrder / selectedItem.price) * 100)}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(
											netProfitSimulation || 0,
											{ decimalPlaces: 3 },
										)}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{formatPercentage((netProfitSimulation
											/ (selectedItem.simulationPrice || 0)) * 100)}
									</TableCell>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							/>
						</TableRow>
						<TableRow>
							<TableCell />
							<TableCell align="right" />
							<TableCell />
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							/>
						</TableRow>
						<TableRow>
							<TableCell>Custo Financeiro</TableCell>
							<TableCell align="right">
								{currencyBRLMask(financialCost, {
									decimalPlaces: 3,
								})}
							</TableCell>
							<TableCell />
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(financialCostSimulation, {
											decimalPlaces: 3,
										})}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							/>
						</TableRow>

						<TableRow>
							<TableCell>Custo Indireto (FPV.ci)</TableCell>
							<TableCell align="right">
								{currencyBRLMask(
									totalUnitValueIndirectCost,
									{ decimalPlaces: 3 },
								)}
							</TableCell>
							<TableCell />
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(
											totalUnitValueIndirectCostSimulation,
											{ decimalPlaces: 3 },
										)}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							/>
						</TableRow>
						<TableRow>
							<TableCell>Comissão</TableCell>
							<TableCell align="right">
								{currencyBRLMask(
									salePriceFormation?.unitValueRangeCommission
										|| 0,
									{ decimalPlaces: 3 },
								)}
							</TableCell>
							<TableCell />
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(
											salePriceFormation?.unitValueRangeCommissionSimulation
										|| 0,
											{ decimalPlaces: 3 },
										)}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							/>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default SalePriceFormationSimulationTable;
