import React, {
	useCallback, useEffect, useRef, useState,
} from 'react';
import {
	Box,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Grid,
	Button,
	IconButton,
	TablePagination,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Add, Remove,
} from '@mui/icons-material';
import { Form, useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import Input from '../../Common/Form/Input';
import useConfirmationDialog from '../../../hooks/useConfirmationDialog';
import { SaveTaskType } from '../../../services/storage';
import { TaskStorageFormik } from '../../../containers/Mobile/Storage/StorageAssets';
import { ITask } from '../../../containers/Mobile/TaskAssets';
import { InventoryTaskStatus } from '../../../enums/InventoryTaskStatus';
import { Loading } from '../../Common/Loading';

interface StorageConfirmationProductsProps {
  getLocationOrigin: (barCode: string, status: InventoryTaskStatus) => void;
  saveTakeTask: (data: SaveTaskType) => void;
  handleConfirmStorage: () => void;
  handleBack: () => void;
  tasks: ITask[];
  loading: boolean;
}

const StorageCheckConfirmation = ({
	getLocationOrigin,
	tasks,
	saveTakeTask,
	handleConfirmStorage,
	handleBack,
	loading,
}: StorageConfirmationProductsProps): JSX.Element => {
	const { enqueueSnackbar } = useSnackbar();
	const { setFieldValue, values } = useFormikContext<TaskStorageFormik>();
	const { requestConfirm, confirmationDialog } = useConfirmationDialog();
	const [expanded, setExpanded] = useState<null | string>(null);
	const barcodeInputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(1);

	const startIndex = page * rowsPerPage;
	const endIndex = startIndex + rowsPerPage;
	const currentTasks = tasks.slice(startIndex, endIndex);

	const handlePageChange = useCallback((_event: unknown, newPage: number): void => {
		setPage(newPage);
		setExpanded(null);
	}, []);

	useEffect(() => {
		if (tasks.length > 0) {
			setExpanded(currentTasks[0]?.id);
		}
	}, [tasks, currentTasks]);

	useEffect(() => {
		if (expanded && barcodeInputRefs.current[expanded]) {
			const barcodeInput = barcodeInputRefs.current[expanded];
			if (barcodeInput) {
				barcodeInput.focus();
			}
		}
	}, [expanded]);

	const handleIncrement = useCallback((taskId: string): void => {
		if (!values[`quantity-${taskId}`]) {
			setFieldValue(`quantity-${taskId}`, 1);
			return;
		}
		setFieldValue(`quantity-${taskId}`, (Number(values[`quantity-${taskId}`]) ?? 0) + 1);
	}, [setFieldValue, values]);

	const handleDecrement = useCallback((taskId: string): void => {
		if (!Number(values[`quantity-${taskId}`])) {
			return;
		}
		setFieldValue(`quantity-${taskId}`, (Number(values[`quantity-${taskId}`]) ?? 0) - 1);
	}, [setFieldValue, values]);

	const confirmQuantity = useCallback((task: ITask): void => {
		const taskId = task.id;
		const quantity = Number(values[`quantity-${task.id}`]);
		saveTakeTask({
			taskId,
			quantity,
		});
		setFieldValue('confirmedTasks', [...values.confirmedTasks, task]);
		setExpanded(taskId);
	}, [saveTakeTask, setFieldValue, values]);

	const handleBlurOrEnter = useCallback((
		event: React.KeyboardEvent<HTMLInputElement>,
		task: ITask,
	): void => {
		const target = event.target as HTMLInputElement;
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			event.preventDefault();
			if (task.product.code === target.value) {
				handleIncrement(task.id);
			} else {
				enqueueSnackbar('Código de barras inválido', {
					variant: 'error',
				});
			}
			setFieldValue(`barcode-${task.id}`, '');
		}
	}, [enqueueSnackbar, handleIncrement, setFieldValue]);

	const handleExpanded = (taskId: string): void => {
		setExpanded((prevState) => (prevState === taskId ? null : taskId));
	};

	useEffect(() => {
		if (values.barcode) {
			getLocationOrigin(values.barcode, InventoryTaskStatus.WAITING);
		}
	}, [values.barcode, getLocationOrigin]);

	const handleChangeRowsPerPage = useCallback((
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	): void => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	}, []);

	if (!loading && tasks.length === 0) {
		return (
			<Box sx={{ mt: 2 }}>
				<Typography textAlign="center">Não há produtos nesta localização.</Typography>
				<Button
					sx={{ mt: 4, width: '100%' }}
					size="large"
					variant="contained"
					onClick={handleBack}
				>
					Ir para Tela Inicial
				</Button>
			</Box>
		);
	}

	if (loading) {
		return <Loading />;
	}

	return (
		<Box>
			<Typography mt={2} variant="h6" align="center" marginBottom={2}>
				Confirme os itens para armazenagem
			</Typography>
			{currentTasks.map((task: ITask) => (
				<Accordion
					key={task.id}
					sx={{ marginBottom: 1 }}
					expanded={expanded === task.id}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls={`panel${task.id}-content`}
						id={`panel${task.id}-header`}
						onClick={() => handleExpanded(task.id)}
					>
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<Box display="flex" alignItems="center" gap={1}>
								<Typography>{`${task.product.code} - ${task.product.description}`}</Typography>
							</Box>
							<Typography sx={{ fontWeight: 'bold', mb: 1 }}>
								{`${task.quantity} ${task.product.measures[0].description}(s)`}
							</Typography>
						</Box>
					</AccordionSummary>
					<AccordionDetails>
						<Form>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<Input.InputField
										label="Código de Barras"
										id={`barcode-${task.id}`}
										name={`barcode-${task.id}`}
										// eslint-disable-next-line no-return-assign
										inputRef={(el) => barcodeInputRefs.current[task.id] = el}
										fullWidth
										onKeyDown={(
											event: React.KeyboardEvent<HTMLInputElement>,
										) => handleBlurOrEnter(event, task)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Box
										sx={{
											width: '100%',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<IconButton disabled={Number(values[`quantity-${task.id}`]) === 0} size="large" onClick={() => handleDecrement(task.id)}>
											<Remove />
										</IconButton>

										<Input.InputField
											id={`quantity-${task.id}`}
											name={`quantity-${task.id}`}
											style={{ width: 100, textAlign: 'center' }}
											type="number"
											required
										/>
										<IconButton size="large" onClick={() => handleIncrement(task.id)}>
											<Add />
										</IconButton>
									</Box>
								</Grid>
								<Grid item xs={12} sm={6} display="flex" justifyContent="space-between" marginTop={2} gap={2}>
									{Number(values[`quantity-${task.id}`]) === Number(task.quantity) ? (
										<Button
											variant="contained"
											size="small"
											color="primary"
											sx={{ width: '100%' }}
											onClick={() => confirmQuantity(task)}
											disabled={Number(values[`quantity-${task.id}`]) <= 0}
										>
											Confirmar
										</Button>
									) : (
										<Button
											variant="outlined"
											size="small"
											color="error"
											sx={{ width: '100%' }}
											disabled={Number(values[`quantity-${task.id}`]) <= 0}
											onClick={() => requestConfirm({
												title: 'Confirmar Inconsistência',
												description: 'Existe uma diferença entre a quantidade informada e a esperada, deseja confirmar mesmo assim?',
												callback: () => confirmQuantity(task),
											})}
										>
											Inconsistente
										</Button>
									)}

								</Grid>
							</Grid>
						</Form>
					</AccordionDetails>
				</Accordion>
			))}

			<Box sx={{
				display: 'flex',
				justifyContent: 'center',
				mt: 4,
				mb: 2,
			}}
			>
				<TablePagination
					component="div"
					count={tasks.length}
					page={page}
					onPageChange={handlePageChange}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					rowsPerPageOptions={[1, 5, 10, 25]}
					labelRowsPerPage="Página"
					labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
				/>
			</Box>

			<Button
				sx={{ width: '100%', mt: 4 }}
				size="large"
				variant="contained"
				onClick={handleConfirmStorage}
			>
				Finalizar Conferência
			</Button>

			{confirmationDialog}
		</Box>

	);
};

export default StorageCheckConfirmation;
