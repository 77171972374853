import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { DataGrid, GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import FilterListIcon from '@mui/icons-material/FilterList';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import DrawerFilter from '../Common/DrawerFilter';
import SalesPriceFormationFilter from './SalePriceFormationFilter';
import { SalePriceFormationQueryParams } from '../../interfaces/SalePriceFormationQueryParams';
import useColumns from '../../hooks/useColumnsPurchaseOrder';
import { useManageColumns } from '../../hooks/useManageColumns';
import { ToolbarComponent } from '../Common/Datagrid/DataGridToolBar';
import DetailsDrawer from './Drawers/DetailsDrawer';
import { salePriceFormationColumns } from '../../constants/salePriceFormationColumns';
import { initialValuesLoad, ISalePriceFormation } from '../../containers/SalePriceFormation/SalePriceFormationAssets';
import useDataGridManagement from '../../hooks/useDataGridManagement';
import { normalizeDataGridOrderBy } from '../../helpers/Utils';
import useConfirmationDialog from '../../hooks/useConfirmationDialog';
import { AlertTypes } from '../../enums/ConfirmationDialogType';

const localStorageKey = 'salesPriceInformation';

const columnsVisibility = [
	'code',
	'revision',
	'priceList',
	'productCode',
	'productDescription',
	'salePrice',
	'totalIndirectCost',
	'status',
	'validity',
	'createdAt',
	'actions',
];

const optionalColumnsVisibility = [
	'updatedAt',
	'costBaseMonth',
	'baseCommission',
	'averageTerm',
	'userResponsible',
	'totalDirectCost',
	'actionJustificative',
];

const initialSortModel: GridSortModel = [
	{ field: 'code', sort: 'desc' },
];

interface SalePriceFormationProps {
	loading: boolean;
	salePriceFormations: ISalePriceFormation[];
	salePriceFormationsPages: number;
	salePriceFormationsPage: number;
	salePriceFormationsTake: number;
	priceLists: string[];
	getSalePriceFormations: (params: SalePriceFormationQueryParams) => void;
	handleEdit: (id: string) => void;
	handleDeleteSalePriceFormation: (id: string) => void;
	getPriceLists(): void;
}

const SalePriceFormation = ({
	loading,
	salePriceFormations,
	salePriceFormationsPages,
	salePriceFormationsPage,
	salePriceFormationsTake,
	priceLists,
	getSalePriceFormations,
	handleEdit,
	handleDeleteSalePriceFormation,
	getPriceLists,
}: SalePriceFormationProps): JSX.Element => {
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [openDetails, setOpenDetails] = useState<ISalePriceFormation | undefined>(undefined);
	const { requestConfirm, confirmationDialog } = useConfirmationDialog();
	const [filterValues, setFilterValues] = useState<SalePriceFormationQueryParams>(() => {
		const storedFilter = localStorage.getItem('salePriceFormationFilter');
		if (storedFilter) {
			return JSON.parse(storedFilter);
		}
		return initialValuesLoad;
	});

	useEffect(() => {
		getPriceLists();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleDelete = useCallback((id: string) => {
		requestConfirm({
			description: 'Tem certeza que deseja excluir a formação de preço selecionada? A Ação não poderá ser desfeita!',
			alertType: AlertTypes.ERROR,
			callback: () => handleDeleteSalePriceFormation(id),
		});
	}, [requestConfirm, handleDeleteSalePriceFormation]);

	const {
		setFilter,
		sortModel,
		onChangePage,
		onChangePageSize,
		onSortModelChange,
	} = useDataGridManagement<SalePriceFormationQueryParams>({
		initialSortModel,
		initialPageSize: salePriceFormationsTake,
		initialPage: salePriceFormationsPage,
		initialFilterValues: filterValues,
		fetchData: getSalePriceFormations,
	});

	const sendFilter = useCallback((values: SalePriceFormationQueryParams) => {
		const pageFilter = {
			...values,
			skip: 0,
			orderBy: normalizeDataGridOrderBy(sortModel) as {
				code: GridSortDirection,
			},
		};
		setFilter(pageFilter);
	}, [setFilter, sortModel]);

	const onDetailsClick = useCallback((id: string) => {
		const selectedFormation = salePriceFormations.find((formation) => formation.id === id);
		setOpenDetails(selectedFormation);
	}, [salePriceFormations]);

	const onCloseDetails = useCallback(() => {
		setOpenDetails(undefined);
	}, []);

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen((prevOpen) => !prevOpen);
	}, []);

	const handleFilterSubmit = useCallback((
		newFilterValues: SalePriceFormationQueryParams,
	) => {
		setFilterValues(newFilterValues);
		setFilterDrawerOpen(false);
		sendFilter(newFilterValues);
		localStorage.setItem('salePriceFormationFilter', JSON.stringify(newFilterValues));
	}, [sendFilter]);

	const countActiveFilters = useCallback((
		filters: Partial<SalePriceFormationQueryParams>,
	): number => Object.entries(filters)
		.filter(([key, value]) => {
			const excludedKeys = ['skip', 'take'];
			if (excludedKeys.includes(key)) {
				return false;
			}
			return value !== '' && value !== undefined;
		})
		.length, []);

	const { columns, defaultVisibility } = useColumns({
		columnsVisibility,
		defaultColumnsOverride: salePriceFormationColumns,
		includeStatusColumn: false,
		optionalColumnsVisibility,
		onEditClick: handleEdit,
		onDeleteClick: handleDelete,
		onDetailsClick,
		editIcon: <RateReviewIcon />,
		editTooltip: 'Revisar',
	});

	const {
		currentColumns,
		columnVisibilityModel,
		handleColumnVisibilityModelChange,
		onRestoreClick,
	} = useManageColumns({
		initialColumns: columns,
		initialVisibility: defaultVisibility,
		localStorageKey,
		isDraggable: true,
	});

	const headerButtonsProps = useMemo((): PageHeaderButtonProps[] => [
		{
			variant: 'contained',
			color: 'primary',
			onClick: toggleFilterDrawer,
			badgeContent: countActiveFilters(filterValues),
			badgecolor: 'error',
			text: 'Filtro',
			startIcon: <FilterListIcon />,
		},
	], [countActiveFilters, filterValues, toggleFilterDrawer]);

	const pageHeaderMemo = useMemo(() => <PageHeader title="Formação do Preço de Venda" buttons={headerButtonsProps} />, [headerButtonsProps]);

	const filterMemo = useMemo(() => (
		<DrawerFilter open={isFilterDrawerOpen} onClose={toggleFilterDrawer}>
			<SalesPriceFormationFilter
				sendFilter={handleFilterSubmit}
				filterValues={filterValues}
				priceLists={priceLists}
			/>
		</DrawerFilter>
	), [filterValues, handleFilterSubmit, isFilterDrawerOpen, priceLists, toggleFilterDrawer]);

	return (
		<Box className="content">
			{pageHeaderMemo}
			{filterMemo}
			<Box component={Paper} sx={{ width: '100%', mt: 2 }}>
				<DataGrid
					autoHeight
					rows={salePriceFormations}
					rowCount={salePriceFormationsPages}
					pagination
					paginationMode="server"
					sortingMode="server"
					columns={currentColumns}
					page={salePriceFormationsPage}
					pageSize={salePriceFormationsTake}
					rowsPerPageOptions={[10, 25, 50, 100]}
					sortModel={sortModel}
					onPageChange={onChangePage}
					onPageSizeChange={onChangePageSize}
					onSortModelChange={onSortModelChange}
					columnVisibilityModel={columnVisibilityModel}
					onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
					components={{ Toolbar: ToolbarComponent(onRestoreClick) }}
					disableSelectionOnClick
					loading={loading}
					experimentalFeatures={{ newEditingApi: true }}
				/>
				{confirmationDialog}
			</Box>
			<DetailsDrawer
				openDetails={openDetails}
				onClose={onCloseDetails}
			/>
		</Box>
	);
};
export default SalePriceFormation;
