import React, {
	useCallback,
	useMemo,
	useState,
} from 'react';
import { Link } from 'react-router-dom';
import { GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import FilterListIcon from '@mui/icons-material/FilterList';
import { IProductWithoutDetails } from '../../containers/Product/ProductAssets';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import ProductTable from './ProductTable';
import ProductFilter from './ProductFilter';
import { Loading } from '../Common/Loading';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import useDataGridManagement from '../../hooks/useDataGridManagement';
import { normalizeDataGridOrderBy } from '../../helpers/Utils';
import DrawerFilter from '../Common/DrawerFilter';

interface ProductProps {
	loading: boolean;
	products: IProductWithoutDetails[];
	productsPages: number;
	productsPage: number;
	productsTake: number;
	getProducts(queryParams: ProductQueryParams): void;
	handleDeleteProduct(id: string): void;
	printLabel(barCode: string): void;
}

const initialSortModel: GridSortModel = [
	{ field: 'code', sort: 'asc' },
];

const Product: React.FC<ProductProps> = ({
	loading,
	products,
	productsPages,
	productsPage,
	productsTake,
	getProducts,
	handleDeleteProduct,
	printLabel,
}) => {
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);

	const {
		setFilter,
		sortModel,
		onChangePage,
		onChangePageSize,
		onSortModelChange,
	} = useDataGridManagement<ProductQueryParams>({
		initialSortModel,
		initialPageSize: productsTake,
		initialPage: productsPage,
		fetchData: getProducts,
	});

	const sendFilter = useCallback((values: Partial<ProductQueryParams>) => {
		const pageFilter = {
			...values,
			skip: 0,
			orderBy: normalizeDataGridOrderBy(sortModel) as {
				name: GridSortDirection,
				code: GridSortDirection,
			},
		};
		setFilter(pageFilter);
	}, [setFilter, sortModel]);

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen(!isFilterDrawerOpen);
	}, [isFilterDrawerOpen]);

	const headerButtonsProps = useMemo((): PageHeaderButtonProps[] => [
		{
			variant: 'contained',
			color: 'primary',
			type: 'submit',
			fullWidth: true,
			component: Link,
			to: 'edit',
			text: 'Novo',
		},
		{
			variant: 'contained',
			color: 'primary',
			onClick: toggleFilterDrawer,
			text: 'Filtro',
			startIcon: <FilterListIcon />,
		},
	], [toggleFilterDrawer]);

	const pageHeaderMemo = useMemo(() => <PageHeader title="Produto" buttons={headerButtonsProps} />, []);

	const filterMemo = useMemo(() => (
		<DrawerFilter open={isFilterDrawerOpen} onClose={toggleFilterDrawer}>
			<ProductFilter
				sendFilter={sendFilter}
			/>
		</DrawerFilter>
	), [isFilterDrawerOpen, sendFilter, toggleFilterDrawer]);

	return (
		<>
			{pageHeaderMemo}
			{filterMemo}
			{loading && <Loading />}
			{!loading && (
				<ProductTable
					loading={loading}
					rows={products}
					productsPages={productsPages}
					productsPage={productsPage}
					productsTake={productsTake}
					sortModel={sortModel}
					onChangePageSize={onChangePageSize}
					onChangePage={onChangePage}
					onSortModelChange={onSortModelChange}
					handleDeleteProduct={handleDeleteProduct}
					printLabel={printLabel}
				/>
			)}
		</>
	);
};

export default Product;
