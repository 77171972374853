import React from 'react';
import {
	GridColDef,
	GridRenderCellParams,
	GridValueFormatterParams,
} from '@mui/x-data-grid';
import { currencyBRLMask } from '../helpers/intl';
import { ItemTypeLabels } from './itemTypeLabels';
import { ItemType } from '../enums/ItemType';
import TruncateTooltipCell from '../components/Common/Datagrid/TruncateTooltipCell';
import { formatValueOrNA, getValueOrNullForBonus } from '../helpers/Utils';

export const profitabilityAnalysisFormColumns: GridColDef[] = [
	{ field: 'nrItem', headerName: 'Item', flex: 0.5 },
	{
		field: 'type',
		headerName: 'Tipo',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueFormatter: (
			params: GridValueFormatterParams,
		) => ItemTypeLabels[params.value as ItemType],
	},
	{
		field: 'codeDescription',
		headerName: 'Produto',
		flex: 2,
		cellClassName: 'small-font-cell',
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.value} />
		),
	},
	{
		field: 'quantity',
		headerName: 'Qtd',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueFormatter: (params) => formatValueOrNA(params),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'price',
		headerName: 'Preço Unitário',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueFormatter: (params) => formatValueOrNA(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'total',
		headerName: 'Total Bruto',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueFormatter: (params) => formatValueOrNA(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'discount',
		headerName: 'Desconto (%)',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueFormatter: (params) => formatValueOrNA(params, (value) => `${value.toFixed(2)}%`),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'discountValue',
		headerName: 'Vlr Desc',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueFormatter: (params) => formatValueOrNA(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'totalWithDiscount',
		headerName: 'Total c/ Desc',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueFormatter: (params) => formatValueOrNA(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'commission',
		headerName: 'Comissão (%)',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueFormatter: (params) => formatValueOrNA(params, (value) => `${value.toFixed(2)}%`),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'commissionValue',
		headerName: 'Vlr Comissão',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueFormatter: (params) => formatValueOrNA(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'totalDirectCost',
		headerName: 'Custo Direto',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueFormatter: (params) => formatValueOrNA(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'grossProfitSaleOrder',
		headerName: 'L. Bruto PV',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueGetter: (params) => getValueOrNullForBonus<number>(params, (param) => {
			const totalWithDiscount = Number(param.row.totalWithDiscount) || 0;
			const totalDirectCost = Number(param.row.totalDirectCost) || 0;
			const grossProfitSaleOrder = totalWithDiscount - totalDirectCost;
			return grossProfitSaleOrder;
		}),
		valueFormatter: (params) => formatValueOrNA(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'netProfitSaleOrder',
		headerName: 'L. Liquido PV',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueGetter: (params) => getValueOrNullForBonus<number>(params, (param) => {
			const totalPrice = Number(param.row.totalWithDiscount) || 0;
			const totalDirectCost = Number(param.row.totalDirectCost) || 0;
			const commissionValue = Number(param.row.commissionValue) || 0;
			const netProfitSaleOrder = (totalPrice - totalDirectCost) - commissionValue;
			return netProfitSaleOrder;
		}),
		valueFormatter: (params) => formatValueOrNA(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'netProfitMarginSaleOrder',
		headerName: 'MLL PV (%)',
		flex: 1,
		cellClassName: 'small-font-cell',
		valueGetter: (params) => getValueOrNullForBonus<number>(params, (param) => {
			const totalPrice = Number(param.row.totalWithDiscount) || 0;
			const totalDirectCost = Number(param.row.totalDirectCost) || 0;
			const commissionValue = Number(param.row.commissionValue) || 0;
			const netProfitSaleOrder = (totalPrice - totalDirectCost) - commissionValue;
			const netProfitMarginSaleOrder = (netProfitSaleOrder / totalPrice) * 100;
			return netProfitMarginSaleOrder;
		}),
		valueFormatter: (params) => formatValueOrNA(params, (value) => `${value.toFixed(2)}%`),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'simulationPrice',
		headerName: 'Total Simulação',
		flex: 1,
		cellClassName: 'small-font-cell highlight-column',
		valueGetter: (params) => getValueOrNullForBonus<number>(params, () => {
			const quantity = Number(params.row.quantity) || 1;
			return Number(params.row.simulationPrice) * quantity;
		}),
		valueFormatter: (params) => formatValueOrNA(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'grossProfitSimulation',
		headerName: 'L. Bruto Sim.',
		flex: 1,
		cellClassName: 'small-font-cell highlight-column',
		valueFormatter: (params) => formatValueOrNA(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'netProfitSimulation',
		headerName: 'L. Liquido Sim.',
		flex: 1,
		cellClassName: 'small-font-cell highlight-column',
		valueFormatter: (params) => formatValueOrNA(params, currencyBRLMask),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
	{
		field: 'netProfitMarginSimulation',
		headerName: 'MLL Simulação (%)',
		flex: 1,
		cellClassName: 'small-font-cell highlight-column',
		valueFormatter: (params) => formatValueOrNA(params, (value) => `${value.toFixed(2)}%`),
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.formattedValue as string} />
		),
	},
];
