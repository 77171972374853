import { AxiosResponse } from 'axios';
import { SaleOrderSimulationQueryParams } from '../interfaces/SaleOrderSimulationQueryParams';
import { ISaleOrderSimulation, ISaleOrderSimulationItem, ISaleOrderSimulations } from '../containers/ProfitabilityAnalysis/ProfitabilityAnalysisAssets';
import api from './api';
import { IMessageResponse } from '../interfaces/MessageResponse';
import { ProfitabilityAnalysisStatus } from '../enums/ProfitabilityAnalysisStatus';

export const getSaleOrderSimulations = (
	params?: SaleOrderSimulationQueryParams,
): Promise<AxiosResponse<ISaleOrderSimulations>> => api.get<ISaleOrderSimulations>('/sale-order-simulation', { params });

export const getSaleOrderSimulationById = (
	id: string,
): Promise<AxiosResponse<ISaleOrderSimulation>> => api.get<ISaleOrderSimulation>(`/sale-order-simulation/${id}`);

export const updateSaleOrderSimulation = (id: string, data: ISaleOrderSimulation): Promise<AxiosResponse<IMessageResponse>> => api.patch<ISaleOrderSimulation, AxiosResponse<IMessageResponse>>(`/sale-order-simulation/${id}`, data);

export const updateSaleOrderSimulationItem = (
	itemId: string,
	data: ISaleOrderSimulationItem,
): Promise<AxiosResponse<IMessageResponse>> => api
	.patch<ISaleOrderSimulationItem, AxiosResponse<IMessageResponse>>(
		`/sale-order-simulation/item/${itemId}`,
		data,
	);
export const updateSelectedRangeSimulation = (
	rangeCommissionId: string,
	salePriceFormationId: string,
	selectedRangeSimulation: boolean,
): Promise<AxiosResponse<IMessageResponse>> => api.patch<IMessageResponse>(
	'/sale-order-simulation/update-selected-range',
	{
		rangeCommissionId,
		salePriceFormationId,
		selectedRangeSimulation,
	},
);

export const updateSelectedAveragePaymentSimulation = (
	averagePaymentTermId: string,
	salePriceFormationId: string,
	selectedAveragePaymentSimulation: boolean,
): Promise<AxiosResponse<IMessageResponse>> => api.patch<IMessageResponse>(
	'/sale-order-simulation/update-selected-average-payment',
	{
		averagePaymentTermId,
		salePriceFormationId,
		selectedAveragePaymentSimulation,
	},
);

export const approveOrRejectSaleOrderSimulation = (
	id: string,
	status: ProfitabilityAnalysisStatus,
): Promise<AxiosResponse<IMessageResponse>> => api.patch<IMessageResponse>(
	`/sale-order-simulation/approve-reject/${id}`,
	{ status },
);

export const deleteSaleOrderSimulation = (id: string): Promise<AxiosResponse<IMessageResponse>> => api.delete<IMessageResponse>(`/sale-order-simulation/${id}`);
