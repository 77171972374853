/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo } from 'react';
import {
	Form, FormikContext, useFormik,
} from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Input from '../Common/Form/Input';
import { InventoryTaskQueryParams } from '../../interfaces/InventoryTaskQueryParams';
import RadioField from '../Common/Form/Radio';
import {
	defaultInventoryTaskQueryParams,
	drawerFilterInventoryPendingTask,
	inventoryTaskStatusFilter,
} from '../../constants/inventoryTask';
import { DatePicker } from '../Common/Form/DatePicker';
import { filterObject } from '../../helpers/Utils';
import { InventoryTaskAction } from '../../enums/InventoryTaskAction';
import { ICompanyWithoutDetails } from '../../containers/Company/CompanyAssets';
import { IBranch } from '../../containers/Branch/BranchAssets';
import { BranchParams } from '../../containers/Inventory/InventoryAssets';
import Autocomplete from '../Common/Form/Autocomplete';

interface FilterProps {
	handlerValueFilter: (values: Partial<InventoryTaskQueryParams>) => void;
    initialValues: InventoryTaskQueryParams
	companies: ICompanyWithoutDetails[]
	branches: IBranch[]
	getBranches: (params: BranchParams) => void;
	loading: boolean;
}

const InventoryPendingTaskFilter = ({
	initialValues,
	handlerValueFilter,
	getBranches,
	companies,
	branches,
	loading,
}: FilterProps): JSX.Element => {
	const onSubmit = useCallback((values: Partial<InventoryTaskQueryParams>) => {
		const all = '99';
		const filterValues = { ...values, status: String(values.status) === all ? '' : values.status };
		localStorage.setItem('inventoryTaskFilterParams', JSON.stringify(filterValues));
		handlerValueFilter(filterObject(filterValues));
	}, [handlerValueFilter]);

	const formik = useFormik({
		validationSchema: drawerFilterInventoryPendingTask,
		validateOnChange: false,
		initialValues,
		onSubmit,
		validateOnMount: true,
	});

	useEffect(() => {
		if (formik.values.companyId) {
			getBranches({
				companyId: formik.values.companyId,
			});
		}
	}, [formik.values.companyId, getBranches]);

	const onReset = useCallback((resetForm) => {
		handlerValueFilter(filterObject(defaultInventoryTaskQueryParams));
		localStorage.setItem(
			'inventoryTaskFilterParams',
			JSON.stringify(filterObject(defaultInventoryTaskQueryParams)),
		);
		resetForm();
	}, [handlerValueFilter]);

	const isSupplyOrDispath = useMemo(() => {
		const types = [InventoryTaskAction.DISPATCH, InventoryTaskAction.SUPPLY];
		return types.includes(initialValues.type as InventoryTaskAction);
	}, [initialValues.type]);

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>
					{isSupplyOrDispath && (
						<Grid item xs={12}>
							<Input.InputField
								id="businessPartnerName"
								name="businessPartnerName"
								label="Fornecedor/Cliente"
								autoComplete="off"
								fullWidth
							/>
						</Grid>
					)}

					<Grid container spacing={2} item xs={12}>
						<Grid item xs={6}>
							<DatePicker
								name="startDate"
								label="Data início"
							/>
						</Grid>

						<Grid item xs={6}>
							<DatePicker
								name="endDate"
								label="Data Final"
							/>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Autocomplete
							name="companyId"
							label="Empresa"
							options={companies}
							valueKey="id"
							valueLabel="code"
							labelKey="name"
							required={false}
							closeOnSelect
							size="medium"
						/>
					</Grid>

					<Grid item xs={12}>
						<Autocomplete
							name="branchId"
							label="Filial"
							options={branches}
							valueKey="id"
							valueLabel="code"
							labelKey="name"
							required={false}
							loading={loading}
							size="medium"
							closeOnSelect
							disabled={!formik.values.companyId}
						/>
					</Grid>

					{isSupplyOrDispath && (
						<Grid item xs={12}>
							<Input.InputField
								id="saleOrder"
								name="saleOrder"
								label="Nº Pedido"
								autoComplete="off"
								fullWidth
							/>
						</Grid>
					)}

					{isSupplyOrDispath && (
						<Grid item xs={12}>
							<Input.InputField
								id="invoiceNumber"
								name="invoiceNumber"
								label="Nº Documento"
								autoComplete="off"
								fullWidth
							/>
						</Grid>
					)}

					<Grid item xs={12}>
						<RadioField
							name="status"
							label="Status da Tarefa"
							options={inventoryTaskStatusFilter}
							orientation="column"
						/>
					</Grid>

					<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
						<Button variant="outlined" onClick={() => onReset(() => formik.resetForm())}>
							Restaurar
						</Button>
						<Button variant="contained" type="submit">
							Filtrar
						</Button>
					</Grid>
				</Grid>
			</Form>
		</FormikContext.Provider>
	);
};

export default InventoryPendingTaskFilter;
