import React, { MouseEvent } from 'react';
import {
	DebouncedFunc, debounce as _debounce, omit, pickBy,
} from 'lodash';
import {
	GridColDef,
	GridSortDirection,
	GridSortModel,
	GridValueFormatterParams,
	GridValueGetterParams,
} from '@mui/x-data-grid';
import { AxiosResponse } from 'axios';
import { format, isValid } from 'date-fns';
import Decimal from 'decimal.js';
import { ILocation } from '../containers/Location/LocationAssets';
import { GroupedTasks } from '../components/Mobile/Expedition/Expedition';
import { ITask } from '../containers/Mobile/TaskAssets';
import { ItemType } from '../enums/ItemType';

export const getNumbersFromPhone = (phone: string): string => {
	let numbers = phone;
	numbers = numbers.replace('(', '');
	numbers = numbers.replace(')', '');
	numbers = numbers.replaceAll(' ', '');
	numbers = numbers.replace('-', '');

	return numbers;
};

export const stopPropagation = (e: MouseEvent<HTMLElement>): void => e.stopPropagation();

export const debounce = (
	func: (...args: any) => any,
	wait?: number,
	options?: {
    leading?: boolean | undefined;
    maxWait?: number | undefined;
    trailing?: boolean | undefined;
  },
): DebouncedFunc<(...args: any
) => any> => _debounce(func, wait || 300, options);

export const getBoundaryYears = (): { minYear: Date; maxYear: Date } => {
	const minYear = new Date();
	const maxYear = new Date();

	minYear.setFullYear(minYear.getFullYear() - 5);
	maxYear.setFullYear(maxYear.getFullYear() + 5);

	return { minYear, maxYear };
};

export const getFirstDayOfCurrentMonth = (): Date => {
	const date = new Date();
	date.setDate(1);
	date.setHours(0, 0, 0, 0);
	return date;
};

export const normalizeDataGridOrderBy = (
	sortModel: GridSortModel,
): { [x: string]: GridSortDirection } | null => {
	if (sortModel.length === 0) {
		return null;
	}

	const { field, sort } = sortModel[0];

	return { [field]: sort };
};

export function filterObject<T extends Record<string, any>>(
	obj: T,
	omitItems: (keyof T)[] = [],
	pickFunction: (value: any) => boolean = (value) => Number.isInteger(value) || typeof value === 'boolean' || Boolean(value),
): Partial<T> {
	let result: Partial<T> = obj;
	if (omitItems.length > 0) {
		result = omit(obj, ...omitItems) as Partial<T>;
	}
	return pickBy(result, pickFunction) as Partial<T>;
}

type Formatter<T> = (value: T) => string;

export const formatValueOrNA = (
	params: GridValueFormatterParams,
	formatter: Formatter<any> = (value: any) => value.toString(),
): string => (params.value ? formatter(params.value) : '-');

export const getStoredFilterWithDates = <T>(
	localStorageKey: string,
	defaultValues?: T,
): T => {
	const storedValues = localStorage.getItem(localStorageKey);
	let storedFilter: T = defaultValues || ({} as T);

	if (storedValues) {
		const parsedValues: T = JSON.parse(storedValues);
		storedFilter = { ...storedFilter, ...parsedValues };
	}

	return storedFilter;
};

export const formatOrRenderValue = (
	column: GridColDef,
	value: any,
): React.ReactNode => {
	const params = { field: column.field, value };
	const formatter = column.valueFormatter || column.valueGetter || column.renderCell;

	if (formatter) {
		const formattedValue = formatter(params as any);
		return (
			(formattedValue as React.ReactElement).props?.value
      ?? formattedValue
      ?? 'N/A'
		);
	}

	return value;
};

export const downloadFile = (response: AxiosResponse): void => {
	const cotentDisposition = response.headers['content-disposition'];
	const link = document.createElement('a');
	const url = URL.createObjectURL(response.data);

	link.setAttribute('download', cotentDisposition.split('filename=')[1].replaceAll('"', ''));
	link.href = url;
	document.body.appendChild(link);
	link.click();

	document.body.removeChild(link);
	URL.revokeObjectURL(url);
};

export const getImageTypeFromBase64 = (base64: string | undefined): string | null => {
	if (!base64) {
		return null;
	}

	switch (true) {
		case base64.startsWith('/9j/'):
			return 'jpeg';
		case base64.startsWith('iVBOR'):
			return 'png';
		case base64.startsWith('R0lGO'):
			return 'gif';
		case base64.startsWith('PHN2Z'):
			return 'svg+xml';
		default:
			return null;
	}
};

export const getBase64Image = (base64String: string): string => {
	const imageType = getImageTypeFromBase64(base64String);
	return imageType ? `data:image/${imageType};base64,${base64String}` : '';
};

export const cpfValidation = (value?: string): boolean => {
	if (!value) return false;

	const validTypes = typeof value === 'string'
		|| Number.isInteger(value)
		|| Array.isArray(value);

	if (!validTypes) return false;

	const numbers = value.toString().match(/\d/g)?.map(Number);

	if (numbers) {
		if (numbers.length !== 11) return false;

		const items = Array.from(new Set(numbers));
		if (items.length === 1) return false;

		const base = numbers.slice(0, 9);
		const digits = numbers.slice(9);

		const calc = (n: number, i: number, x: number): number => n * (x - i);

		const sum = (r: number, n: number): number => r + n;

		const digit = (n: number): number => {
			const rest = n % numbers.length;
			return rest < 2 ? 0 : numbers.length - rest;
		};

		const calc0 = base
			.map((n, i) => calc(n, i, numbers.length - 1))
			.reduce(sum, 0);
		const digit0 = digit(calc0);

		if (digit0 !== digits[0]) return false;

		const calc1 = base
			.concat(digit0)
			.map((n, i) => calc(n, i, numbers.length))
			.reduce(sum, 0);
		const digit1 = digit(calc1);

		return digit1 === digits[1];
	}

	return false;
};

export const cnpjValidation = (value?: string): boolean => {
	if (!value) return false;

	const validTypes = typeof value === 'string'
		|| Number.isInteger(value)
		|| Array.isArray(value);

	if (!validTypes) return false;

	const match = value.toString().match(/\d/g);
	const numbers = Array.isArray(match) ? match.map(Number) : [];

	if (numbers.length !== 14) return false;

	const items = Array.from(new Set(numbers));
	if (items.length === 1) return false;

	const calc = (x: number): number => {
		const slice = numbers.slice(0, x);
		let factor = x - 7;
		let sum = 0;

		for (let i = x; i >= 1; i -= 1) {
			const n = slice[x - i];
			sum += n * factor;
			factor -= 1;
			if (factor < 2) factor = 9;
		}

		const result = 11 - (sum % 11);

		return result > 9 ? 0 : result;
	};

	const digits = numbers.slice(12);

	const digit0 = calc(12);
	if (digit0 !== digits[0]) return false;

	const digit1 = calc(13);
	return digit1 === digits[1];
};

export const formatDate = (dateString: string): Date => {
	const [year, month, day] = dateString.split('-').map(Number);
	return new Date(year, month - 1, day);
};

export const formatDateAndHours = (value: Date | string): string => {
	const [date, time] = new Date(value).toISOString().split('T');
	return `${date.split('-').reverse().join('/')} ${time.slice(0, 5)}`;
};

export const formatDateTime = (dateTime: Date | null | undefined): string => {
	if (dateTime === null || dateTime === undefined || !isValid(dateTime)) {
		return 'Data inválida';
	}
	return format(dateTime, 'HH:mm');
};

export const formatHoursMinutes = (timeString: string | undefined): string => {
	if (timeString === undefined) {
		return 'hora inválida';
	}
	const parts = timeString.split(':');
	if (parts.length !== 2 || parts.some((part) => Number.isNaN(Number(part)))) {
		return 'hora inválida';
	}
	const [hours, minutes] = parts.map(Number);
	return `${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}min`;
};

export const statusColor = (
	executedPercentage: number,
	completionPercentage: number,
): 'primary' | 'success' | 'error' => {
	if (executedPercentage === completionPercentage) {
		return 'primary';
	} if (executedPercentage > completionPercentage) {
		return 'success';
	}
	return 'error';
};

export const removeDuplicates = <T>(items: T[], key: keyof T): T[] => items.filter(
	(item, index, self) => index === self.findIndex((t) => t[key] === item[key]),
);

export const convertHoursToDecimal = (time: string): string => {
	const [hours, minutes] = time.split(':').map(Number);
	const decimalMinutes = new Decimal(minutes).div(60).toFixed(2);
	const totalHours = new Decimal(hours).add(decimalMinutes).toFixed(2);
	return totalHours;
};

export const formatTimeInput = (input: string): string => {
	let value = input.replace(/[^0-9:]/g, '').replace(/:/g, '');
	if (value.length > 2) {
		value = `${value.slice(0, 2)}:${value.slice(2, 4)}`;
	}
	return value;
};

export function parseCurrencyToFloat(value: string): number {
	const formattedValue = value.replace(/\./g, '').replace(',', '.');
	return parseFloat(formattedValue);
}

export function generateMonthYearOptions(
	startYear: number,
	startMonth: number,
	endYear: number,
	endMonth: number,
): string[] {
	const options: string[] = [];
	const months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];

	for (let year = startYear; year <= endYear; year += 1) {
		const start = year === startYear ? startMonth : 1;
		const end = year === endYear ? endMonth : 12;
		for (let month = start; month <= end; month += 1) {
			const monthName = months[month - 1];
			options.push(`${monthName}/${year}`);
		}
	}
	return options;
}

export const formatCode = (code: number): string => `FPV-${code.toString().padStart(6, '0')}`;

export const groupTasksBySaleOrder = (tasks: ITask[]): GroupedTasks => tasks.reduce((acc, task) => {
	const { saleOrder: saleOrderKey } = task.inventoryTaskInvoice;
	const locationKey = task.locationOrigin?.barCode;
	if (!locationKey) {
		return acc;
	}

	if (!acc[saleOrderKey]) {
		acc[saleOrderKey] = { saleOrder: saleOrderKey, locations: {} };
	}
	if (!acc[saleOrderKey].locations[locationKey]) {
		acc[saleOrderKey].locations[locationKey] = { tasks: [] };
	}
	acc[saleOrderKey].locations[locationKey].tasks.push(task);
	return acc;
}, {} as GroupedTasks);

export const formatDecimalValue = (value: number): string => new Decimal(value)
	.toFixed(2)
	.replace('.', ',')
	.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

export const getValueOrNullForBonus = <T>(
	params: GridValueGetterParams<any, any>,
	valueGetterFunction: (params: GridValueGetterParams<any, any>) => T,
): T | null => {
	if (params.row.type === ItemType.BONUS || params.row.id === 'totalBonus') {
		return null;
	}
	return valueGetterFunction(params);
};

export const formatPercentage = (value: number): string => {
	if (!Number.isFinite(value) || Number.isNaN(value)) {
		return '0,000 %';
	}
	return `${value.toFixed(3).replace('.', ',')} %`;
};
