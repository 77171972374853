import React, { useCallback } from 'react';
import { Form, Formik } from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Input from '../Common/Form/Input';
import { SalePriceFormationQueryParams } from '../../interfaces/SalePriceFormationQueryParams';
import { initialValuesLoad, statusOptions } from '../../containers/SalePriceFormation/SalePriceFormationAssets';
import { DatePicker } from '../Common/Form/DatePicker';
import RadioField from '../Common/Form/Radio';
import Select from '../Common/Form/Select';

interface SalesPriceFormationFilterProps {
	sendFilter: (values: SalePriceFormationQueryParams) => void;
	filterValues: SalePriceFormationQueryParams;
	priceLists: string[];
}

const SalesPriceFormationFilter: React.FC<SalesPriceFormationFilterProps> = ({
	sendFilter,
	filterValues,
	priceLists,
}) => {
	const onSubmit = (values: SalePriceFormationQueryParams): void => {
		sendFilter(values);
	};

	const onReset = useCallback((resetForm) => {
		sendFilter(initialValuesLoad);
		resetForm();
		localStorage.removeItem('salePriceFormationFilter');
	}, [sendFilter]);

	return (
		<Formik initialValues={filterValues} onSubmit={onSubmit}>
			{({ resetForm }) => (
				<Form>
					<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>
						<Grid item xs={12} sm={6}>
							<DatePicker name="startDate" label="Ínicio da Integração" />
						</Grid>
						<Grid item xs={12} sm={6}>
							<DatePicker name="endDate" label="Fim da Integração" />
						</Grid>
						<Grid item xs={12} sm={6}>
							<DatePicker name="startValidity" label="Ínicio da Vigência" />
						</Grid>
						<Grid item xs={12} sm={6}>
							<DatePicker name="endValidity" label="Fim da Vigência" />
						</Grid>
						<Grid item xs={12}>
							<Input.InputField
								id="description"
								name="description"
								label="Código/Descrição do Produto"
								autoComplete="off"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Grid item xs={12}>
								<Select
									name="priceList"
									label="Tabela"
									options={priceLists.map((priceList) => ({ label: priceList, value: priceList }))}
									labelKey="label"
									valueKey="value"
								/>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<RadioField
								name="status"
								label="Status"
								options={statusOptions}
								orientation="column"
							/>
						</Grid>
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
							<Button variant="outlined" onClick={() => onReset(resetForm)}>
								Restaurar
							</Button>
							<Button variant="contained" type="submit">
								Filtrar
							</Button>
						</Grid>
					</Grid>
				</Form>
			)}
		</Formik>
	);
};

export default SalesPriceFormationFilter;
